/// <reference path="../tsd/jquery.d.ts"/>
/// <reference path="../tsd/jqueryui.d.ts"/>

/// <reference path="../tsd/ywshare.d.ts"/>

/**
 * 目前用的
 * JQUI v1.10.3
 * JQUERY v1.8.3
 *
*/


//这个NS class 是从 common.js 移过来的
namespace YWTS {
    export class Cmd {
        cmd: string;
        token: string;
        version: number;//版本
        pageNo: number;//页码
        onePageNum: number;//每页数量
        params: any;
        constructor(cmd: string, params :any) {
            this.cmd = cmd;
            this.token = ns.token;
            this.version = 1;
            this.pageNo = params.pageNo;
            this.onePageNum = params.onePageNum;
            this.params = params;
        }
    }
    export class This {
        value: string;
        constructor(_this :any){
            this.value = _this.value;
        }
    }
    export class NS {
        version: string;
        lang: string;
        basePath: string;
        apiPath: string;
        uploadPath: string;
        requestParam: any;
        token: string;
        onePageNum: number;
        corpId: number;
        corpName: string;
        //备案调试开关
        recordDebug: boolean;

        dict: DICT;
        func: FUNC;

        menuId: any;
        subMenuId: any;
        userName: any;
        module: any;

        //登录后返回的原始子菜单
        subMenuObj: any;
        //详细划分后的子菜单
        subMenus: any;

        deptId: any;
        userId: any;
        userType: any;
        sessionId: any;
        uType: any;
        //模板格式化数据
        templateJsonData: any;

        /** 批量导入异常信息 */
        failInfo: any;

        constructor() {
            //这儿把common.js 中的值初始化过来
            this.version = '1.0';
            this.lang = "zh";
            this.basePath = '/';
            this.apiPath = "/api";
            this.uploadPath = '/fileUpload';

            this.requestParam = [];
            this.subMenuObj = null;
            this.subMenus = {};

            this.token = '';
            this.onePageNum = 10;
            this.corpId = 1;
            this.recordDebug = false;

            this.corpName = "安徽江淮重卡";

            this.dict = new YWTS.DICT();
            this.func = new YWTS.FUNC();
            this.templateJsonData = "";
            this.failInfo = null;
        }

        nameSpace(str: string): void {
            var arr = str.split('.');
            var o: { [index: string]: any } = ns;
            for (var i = 0; i < arr.length; i++) {
                o[arr[i]] = o[arr[i]] || {};
                o = o[arr[i]];
            }
        }

        ajax(cmdName: string, params: any, callBack: (data: any) => any): void {
            //增加 uType设置，区分请求
            if (params != null && typeof (params.uType) === "undefined") {
                params.uType = ns.uType || 1;
            }
            var jsonString = JSON.stringify(new Cmd(cmdName, params));

            $.ajax({
                url: ns.apiPath,
                timeout: 600000,
                type: "POST",
                dataType: "json",
                contentType: "application/x-www-form-urlencoded",
                data: jsonString,
                success: function ajaxReultData(data: any) {
                    if (data != null) {
                        data.detail || (data.detail = {
                            'dataList': [],
                            'totalRecordNum': 0
                        });
                        data.detail.dataList || (data.detail.dataList = []);
                        data.detail.totalRecordNum || (data.detail.totalRecordNum = 0);
                        if (data.result && data.result != 0) {
                            ns.checkUserIsLogin(data.result);
                        }
                    }
                    else {
                        data = {'result': -1, 'resultNote': "服务器未返回数据！"};
                    }
                    callBack(data);
                }
            });
        }

        ajaxSync(cmdName: string, params: any, callBack: (data: any) => any): void {
            //增加 uType设置，区分请求
            if( params != null && typeof (params.uType) === "undefined" )
            {
                params.uType = ns.uType || 1;
            }
            var jsonString = JSON.stringify(new Cmd(cmdName, params));

            $.ajax({
                url: ns.apiPath,
                timeout: 10000,
                type: "POST",
                dataType: "json",
                contentType: "application/x-www-form-urlencoded",
                data: jsonString,
                async: false,
                success: function ajaxReultData(data: any) {
                    if (data != null) {
                        data.detail || (data.detail = {
                            'dataList': [],
                            'totalRecordNum': 0
                        });
                        data.detail.dataList || (data.detail.dataList = []);
                        data.detail.totalRecordNum || (data.detail.totalRecordNum = 0);
                        if (data.result && data.result != 0) {
                            ns.checkUserIsLogin(data.result);
                        }
                    }
                    else {
                        data = {'result': -1, 'resultNote': '服务器未返回数据！'};
                    }

                    callBack(data);
                }
            });
        }

        /***
         * 获取今月对应上月的这一天
         * @returns {any}
         */
        getLastMonthday(): string {
            let now = new Date();
            let lastMonthDay = new Date();
            //今天-1月
            lastMonthDay.setFullYear(now.getFullYear(), now.getMonth() - 1, now.getDate());
            let yearl = lastMonthDay.getFullYear();//getYear()+1900=getFullYear()
            let monthl = lastMonthDay.getMonth() + 1;
            let dayl = lastMonthDay.getDate();
            return yearl + '-' + monthl + '-' + dayl

        }

        /***
         * 获取jbox按钮配置
         * 设置按钮中英切换和宽度
         * @returns {any}
         */
        getOptions(): any {
            let confirm = ns.lang === 'zh' ? '确定' : 'confirm';
            let cancel = ns.lang === 'zh' ? '取消' : 'cancel';
            let options: any = {"buttons": {}, "width": 400};
            options.buttons[confirm] = "ok";
            options.buttons[cancel] = "cancel";
            return options;

        }

        /**
         * 设置时间选择插件中日期输入框为只读状态
         */
        setDaterangepickerInput(): void {
            $(".input-mini").attr("readonly", "readonly");
        }

        /***
         * 获取datepicker国际化配置
         * 增加 时间格式化格式设置（针对bootstrap2）
         * @param format {string} 时间格式化格式
         * @returns {any}
         */
        getDateOptionsAndSetFormat(format:string): any{
            let options:any = ns.getDateOptions();
            options.format = typeof format === "undefined"?"YYYY-MM-DD HH:mm:ss":format;
            return options;
        }

        /***
         * 获取datepicker国际化配置
         * @returns {any}
         */
        getDateOptions(): any {
            let confirm = ns.lang === 'zh' ? '确定' : 'confirm';
            let cancel = ns.lang === 'zh' ? '取消' : 'cancel';
            let fromLabel = ns.lang === 'zh' ? '开始时间' : 'startTime';
            let toLabel = ns.lang === 'zh' ? '结束时间' : 'endTime';
            let monthNames: any = ns.lang === 'zh' ? ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
                : ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
            let options: any = {
                applyLabel: "confirm",
                cancelLabel: "cancel",
                fromLabel: "startTime",
                toLabel: "endTime",
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
            };
            //daysOfWeek: ["日","一","二","三","四","五","六"]
            options.applyLabel = confirm;
            options.cancelLabel = cancel;
            options.fromLabel = fromLabel;
            options.toLabel = toLabel;
            options.monthNames = monthNames;
            return options;
        }

        /**
         * 判断字符串是否为空
         * @param str {string}
         * @return {boolean}, 返回false表示含有非法字符
         */
        isEmpty(str: string): boolean {
            return (typeof str === "undefined" || str === "" || str === null);
        }

        /**
         * 判断字符串是否不为空
         */
        isNotEmpty(str: string): boolean {
            return !this.isEmpty(str);
        }

        showWarn(title: string): void {
            Notify(title, 'bottom-right', '5000', 'warning', 'fa-warning', true);
        }

        showSuccess(title: string): void {
            Notify(title, 'bottom-right', '5000', 'success', 'fa-check', true);
        }

        showInfo(title: string): void {
            Notify(title, 'bottom-right', '5000', 'success', 'fa-check', true);
        }

        showAlert(title: string): void {
            $.jBox.tip(title, "error");
        }

        genNoty(level: string, content: string): void {
            $.jBox.tip(content, level);
            return;
        }

        /**
         * 验证非法字符
         * @param checkStr {string}
         * @return {boolean}, 返回false表示含有非法字符
         */
        checkStrValid(checkStr: string): boolean {
            if (typeof checkStr === "undefined") {
                return true;
            }
            var str = ["!", "#", "$", "%", "/", "\\", "'", "*", "&", "|", "\"", "."];
            for (let i = 0; i < str.length; i++) {
                if (checkStr.indexOf(str[i]) >= 0) {
                    return false;
                }
            }
            return true;
        }

        /**
         * 验证终端ID是否正确
         * @param checkStr {string}
         * @return {boolean}, 返回false表示含有非法字符
         */
        IsDeviceId(str: string): boolean {
            var no = $.trim(str);
            if (no == "") {
                return true;
            }
            var patrn = /^[A-Za-z0-9]+$/;
            if (!patrn.exec(no)) {
                return false;
            }
            return true;
        }


        /**
         * 验证车牌号是否正确
         * @param str {string}
         * @return {boolean}
         */
        isCarNo(str: string): boolean {
            var no = $.trim(str);
            if (no == "") {
                return true;
            }
            var patrn = /^[(\u4e00-\u9fa5)|(a-zA-Z)]{1}[a-zA-Z]{1}[a-zA-Z_0-9]{4,6}[a-zA-Z_0-9_\u4e00-\u9fa5]$/;
            if (!patrn.exec(no)) {
                return false;
            }
            return true;
        }

        /**
         * 验证Email是否正确
         * @param strEmail {string}
         * @return {boolean}
         */
        isEmail(strEmail: string): boolean {
            var pattern = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
            if (strEmail == '') {
                return true;
            }
            return pattern.test(strEmail);
        }

        /**
         * 验证 0 或 正整数
         * @param value {string}
         * @return {boolean}
         */
        isPositiveInteger(value: string): boolean {
            var re = /^(([1-9]{1}\d*)|([0]{1}))$/;
            return re.test(value);
        }

        /**
         * 正整数验证
         * @param checkStr {string}
         * @return {boolean}
         */
        checkIntegerValid(checkStr: string): boolean {
            var reg = /^[0-9]*[1-9][0-9]*$/;
            return reg.test(checkStr);
        }

        /**
         * 生成格式化的排量显示字符串
         * @param str {string}
         * @return {string}
         */
        formatDisplacement(str: string): string {
            var reg = /^([1-9]\d|\d).\dT$/;
            if (!reg.test(str)) {
                return str + "L";
            }
            return str;
        }

        /**
         * 字符串格式数字保留一位小数
         * @param str {string}
         * @return {string}
         */
        formatNumberOne(str: string): string {
            var reg = /([0-9]+.[0-9])[0-9]*/;
            if (reg.test(str)) {
                return str.replace(reg, "$1");
            }
            return str;
        }


        /**
         * 判断日期区间的合法性，若提交间隔限制，可进行限制判断
         * @param startTime {string}
         * @param endTime {string}
         * @param dateLimit {number}
         * @return {boolean}
         */
        checkPeriodDateValid(startTime: string, endTime: string, dateLimit: number): boolean {
            var startTimeArray = startTime.split("-");
            var endTimeArray = endTime.split("-");
            startTime = startTimeArray.join("/");
            endTime = endTimeArray.join("/");

            var startDate = new Date(startTime);
            var endDate = new Date(endTime);

            if (endDate.getTime() - startDate.getTime() < 0) {
                alert("结束时间不能小于开始时间");//, "提示"
                return false;
            }

            if (typeof dateLimit != "undefined") {
                if ((endDate.getTime() - startDate.getTime() + 1) / (24 * 60 * 60 * 1000) > dateLimit) {
                    alert("查询时间段不能超过" + dateLimit + "天");//, "提示"
                    return false;
                }
            }

            return true;
        }

        /**
         *
         * @param startTime {string}
         * @param endTime {string}
         * @param dateLimit {number}
         * @return {boolean}
         */
        checkPeriodDateValidV2(startTime: string, endTime: string, dateLimit: number): boolean {
            var startTimeArray = startTime.split("-");
            var endTimeArray = endTime.split("-");
            startTime = startTimeArray.join("/");
            endTime = endTimeArray.join("/");

            var startDate = new Date(startTime);
            var endDate = new Date(endTime);


            if (endDate.getTime() - startDate.getTime() < 0) {
                return false;
            }

            if (typeof dateLimit != "undefined") {
                if ((endDate.getTime() - startDate.getTime() + 1) / (24 * 60 * 60 * 1000) > dateLimit) {
                    return false;
                }
            }

            return true;
        }

        /**
         * 比较日期大小
         * @param startTime {string}
         * @param endTime {string}
         * @return {boolean}
         */
        compareDate(startTime: string, endTime: string): boolean {
            var startTimeArray = startTime.split("-");
            var endTimeArray = endTime.split("-");
            startTime = startTimeArray.join("/");
            endTime = endTimeArray.join("/");

            var startDate = new Date(startTime);
            var endDate = new Date(endTime);

            if (endDate.getTime() - startDate.getTime() < 0) {
                return false;
            }
            else {
                return true;
            }
        }

        /**
         * 获取指定日期距当前日期天数 （指定日期大于当前日期）
         * @param dateStr {string}
         * @return {number}
         */
        getDaysFromToday(dateStr: string): number {
            var dateStrArray = dateStr.split("-");
            dateStr = dateStrArray.join("/");

            var dateStrMilli = new Date(dateStr).getTime();
            var todayMilli = new Date().getTime();

            return Math.floor((dateStrMilli - todayMilli) / (24 * 3600 * 1000));
        }

        /**
         * 获取指定日期
         * @param date {string}
         * @param step {number}
         * @return {string}
         */
        getAssignDate(date: string, step: number): string {
            var dateArray = date.split("-");
            var dateTime = dateArray.join("/");

            var newDate = new Date(dateTime);

            //获取step天后的日期，若为负数，可获取过去的日期
            newDate.setDate(newDate.getDate() + step);

            var y = newDate.getFullYear().toString();
            var m = (newDate.getMonth() + 1).toString();//获取当前月份的日期
            var d = newDate.getDate().toString();

            if (parseInt(m) < 10) {
                m = "0" + m;
            }

            if (parseInt(d) < 10) {
                d = "0" + d;
            }

            return y + "-" + m + "-" + d;
        }

        /**
         * 根据月份步长获取指定日期
         * @param date {string}
         * @param step {number}
         * @return {string}
         */
        getAssignDateByMonth(date: string, step: number): string {
            var dateArray = date.split("-");
            var dateTime = dateArray.join("/");

            var newDate = new Date(dateTime);

            //获取step天后的日期，若为负数，可获取过去的日期
            newDate.setMonth(newDate.getMonth() + step);

            var y = newDate.getFullYear().toString();
            var m = (newDate.getMonth() + 1).toString();//获取当前月份的日期
            var d = newDate.getDate().toString();

            if (parseInt(m) < 10) {
                m = "0" + m;
            }

            if (parseInt(d) < 10) {
                d = "0" + d;
            }

            return y + "-" + m + "-" + d;
        }

        /**
         * 根据月份步长获取指定日期
         * @param datetime {string}
         * @param seconds {number}
         * @return {string}
         */
        getAssignTime(datetime: string, seconds: number): string {
            var dateArray = datetime.split("-");
            var dateTime = dateArray.join("/");

            var newDateTime = new Date(dateTime).getTime() + seconds * 1000;

            return ns.dateFormat(new Date(newDateTime), "yyyy-MM-dd hh:mm:ss");
        }

        /**
         * 获取今天日期
         * @return {string}
         */
        getToday(): string {
            var nowDate = new Date();
            var y = nowDate.getFullYear().toString();
            var m = (nowDate.getMonth() + 1).toString();
            var d = nowDate.getDate().toString();

            if (parseInt(m) < 10) {
                m = "0" + m;
            }

            if (parseInt(d) < 10) {
                d = "0" + d;
            }

            return y + "-" + m + "-" + d;
        }

        /**
         * 获取本月日期
         * @return {string}
         */
        getCurrentMonth(): string {
            var nowDate = new Date();
            var y = nowDate.getFullYear().toString();
            var m = (nowDate.getMonth() + 1).toString();
            var d = nowDate.getDate().toString();

            if (parseInt(m) < 10) {
                m = "0" + m;
            }
            return y + "-" + m;
        }

        /**
         * 获取时间数组
         * @param time {string}
         * @return {[index: string]: string}
         */
        getTimeArray(time: string): { [index: string]: string } {
            var timeArray = time.split(" ");
            var dateArray = timeArray[0].split("-");
            var hmsArray = timeArray[1].split(":");
            var detailArray: { [index: string]: string } = {};

            detailArray["year"] = dateArray[0];
            detailArray["month"] = dateArray[1];
            detailArray["day"] = dateArray[2];
            detailArray["hour"] = hmsArray[0];
            detailArray["minutes"] = hmsArray[1];
            detailArray["seconds"] = hmsArray[2];

            return detailArray;
        }

        /**
         * 获取该月份的天数
         * @param myMonth {number}
         * @return {number}
         */
        getMonthDays(myMonth: number): number {
            var now = new Date();
            var nowYear = now.getFullYear();
            var monthStartDate = new Date(nowYear, myMonth, 1);
            var monthEndDate = new Date(nowYear, myMonth + 1, 1);
            var days = (monthEndDate.getTime() - monthStartDate.getTime()) / (24 * 60 * 60 * 1000);

            return days;
        }

        /**
         * 获取该月份的结束时间
         * @return {string}
         */
        getLastMonthStartDate(): string {
            var now = new Date();
            var nowYear = now.getFullYear(); //当前年
            nowYear += (nowYear < 2000) ? 1900 : 0;  //

            var lastMonthDate = new Date();  //上月日期
            lastMonthDate.setDate(1);
            lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
            var lastMonth = lastMonthDate.getMonth();

            var lastMonthStartDate = new Date(nowYear, lastMonth, 1);
            return ns.dateFormat(lastMonthStartDate, "yyyy-MM-dd");
        }

        /**
         * 获取该月份的结束时间
         * @param date {string}
         */
        getLastMonthEndDate(date: string): string {
            var dateArray = date.split("-");
            date = dateArray.join("/");

            var now = new Date(date);
            var nowYear = now.getFullYear();       //当前年
            nowYear += (nowYear < 2000) ? 1900 : 0;  //

            var lastMonthDate = new Date(date);  //上月日期
            lastMonthDate.setDate(1);

            var lastMonth = lastMonthDate.getMonth();

            var lastMonthEndDate = new Date(nowYear, lastMonth, ns.getMonthDays(lastMonth));
            return ns.dateFormat(lastMonthEndDate, "yyyy-MM-dd");
        }

        /**
         * 检查是否未定义，如未定义则返回空
         * @param obj {string}
         * @return {string}
         */
        checkUndefined(obj: string): string {
            return (typeof obj === "undefined" ? "" : obj);
        }

        /**
         * 回车搜索函数
         * @param selectStr {string}
         * @param callBack {() => void}
         */
        enterSubmit(selectStr: string, callBack: () => void): void {
            $(selectStr).bind('keyup', function (e) {
                if (e.keyCode == 13) {
                    callBack();
                    return false;
                }

                return true;
            });
        }

        /**
         * 验证手机号是否正确
         * @param str {string}
         * @return {boolean}
         */
        isMobel(str: string): boolean {
            if ($.trim(str) == '')
                return false;
            var myreg = /^((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)$/;// ^((\+86)|(86))?(1)\d{10}$/;
            if (!myreg.test(str)) {
                return false;
            } else {
                return true;
            }
        }

        /**
         * 验证电话号码
         * @param checkStr {string}
         * @return {boolean}
         */
        checkLinkPhoneValid(checkStr: string): boolean {
            var tel = /^(\(\d{3,4}\)|\d{3,4}\-{0,1})?\d{7,8}(\-\d{1,5})?$/;
            var mobile = /^1[3|4|5|8][0-9]\d{4,8}$/;

            var test1 = tel.test(checkStr);
            var test2 = mobile.test(checkStr);

            return (test1 || test2);
        }

        /**
         * 电话验证(包含400及95555等特殊电话号码)
         * @param checkStr {string}
         * @return {boolean}
         */
        checkPhoneValid(checkStr: string): boolean {
            var reg = /^(\d{3,4}\-?\d{7,8}|1\d{10}|9\d{4}|\d{3}\-?\d{3}\-?\d{4})$/;
            if (reg.test(checkStr)) {
                return true;
            }
            return false;
        }

        /**
         * 传真校验
         * @param checkStr {string}
         * @return {boolean}
         */
        checkFaxValid(checkStr: string): boolean {
            var reg = /^(\d{3,4}-{0,1})?\d{7,8}$/;
            if (reg.test(checkStr)) {
                return true;
            }
            return false;
        }

        /**
         * 邮编校验
         * @param checkStr {string}
         * @return {boolean}
         */
        checkPostValid(checkStr: string): boolean {
            var reg = /^[0-9]{6}$/;
            if (reg.test(checkStr)) {
                return true;
            }
            return false;
        }

        /**
         * 价格验证
         * @param price {string}
         * @return {boolean}
         */
        checkPriceValid(price: string): boolean {
            var reg = /(^[1-9]{1}\d{0,6}$)|(^[1-9]{1}\d{0,6}\.{1}\d{1,2}[0]*$)|(^[0]{1}\.{1}\d{0,1}[1-9]{1}[0]*$)/;
            if (!reg.test(price)) {
                return false;
            } else {
                return true;
            }
        }

        /**
         * 匹配1-7位整数、 含有1位小数、或含有许多0的小数
         * @param price {string}
         * @return {boolean}
         */
        checkPriceValidV2(price: string): boolean {
            var reg = /(^[1-9]{1}\d{0,6}$)|(^[1-9]{1}\d{0,6}\.{1}\d{0,1}[0]*$)|(^[0]{1}\.{1}\d{0,1}[1-9]{1}[0]*$)/;
            if (!reg.test(price)) {
                return false;
            } else {
                return true;
            }
        }

        /**
         * 浮点数检验：根据传入的整数、小数长度最大限制来检查浮点数是否合规
         * @param {string} float 最大整数长度
         * @param {number} integer 最大整数长度(默认7位数限制)
         * @param {number} decimal 最大小数长度(默认3位限制)
         * @returns {boolean}
         */
        checkFloatNum(float:string, integer: number|null,decimal: number|null): boolean {

            if( float == null )
            {
                return true;
            }
            let regStr = "";
            if( integer != null && integer > 0 )
            {
                regStr = "(0|([1-9][0-9]{0," + (integer-1) + "}))";
            }
            else
            {
                regStr = "(0|([1-9][0-9]{1,6}))";
            }
            if( decimal != null && decimal > 0 )
            {
                regStr += "(\\.[0-9]{1," + decimal + "})?";
            }
            else
            {
                regStr += "(\\.[0-9])?";
            }

            return new RegExp("^" + regStr +"$","g").test(float);
        }

        /**
         * 限制文本框输入非数字
         * @param obj {any}
         */
        chkPrice(obj: any): void {
            obj.value = this.checkValuePrice(obj.value);
        }

        /**
         * 限制文本框输入非数字
         * @param value {string}
         */
        checkValuePrice(value: string): string {
            value = value.replace(/[^\d.]/g, "");
            // 必须保证第一位为数字而不是.
            value = value.replace(/^\./g, "");
            // 保证只有出现一个.而没有多个.
            value = value.replace(/\.{2,}/g, ".");
            // 保证.只出现一次，而不能出现两次以上
            value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            return value;
        }

        /**
         * 替换自定义正则字符 并限制最大长度
         * @param str 被操作字符
         * @param reg 正则
         * @param maxlength 最大长度 0时表示不限制长度
         */
        replackCode(str: string, reg: RegExp, maxlength: number): string {
            str = str.replace(reg, '');
            if (maxlength > 0) {
                str = str.substr(0, maxlength);
            }
            return str;
        }

        /**
         * 替换非法字符
         * 并限制长度为25个
         * @param o
         */
        replaceCode(o: any): void {
            o.value = this.replackCode(o.value, /\W/g, 25);
        }

        /**
         * 替换非法字符
         * 不限制长度
         * @param o
         */
        replaceNoLength(o: any): void {
            o.value = this.replackCode(o.value, /\W/g, 0);
        }

        /**
         * 替换非数字字符
         * 并限制长度为11个
         * @param o
         */
        replaceNoCode(o: any): void {
            o.value = this.replackCode(o.value, /\D/g, 11);
        }

        /**
         * 替换非数字字符
         * 并限制长度为maxLength个
         * @param o
         * @param {number} maxLength
         */
        replaceNoCode2(o: any, maxLength: number): void {
            // o.value=o.value.replace(/\D/g, '');
            // o.value=o.value.substr(0,maxLength);
            this.replaceCodeCommon(o, /\D/g, maxLength);
        }

        /**
         * 限制文本框输入非数字
         * @param o
         */
        chkLast(o: any): void {
            // 如果出现非法字符就截取掉
            if (o.value.substr((o.value.length - 1), 1) == '.')
                o.value = o.value.substr(0, (o.value.length - 1));
        }

        /**
         * 数字校验
         * @param value
         * @returns {string}
         */
        checkDigital(value: any): string {
            value = this.replackCode(value, /[^\d]/g, 11);
            return value;
        }

        checkDigitalFromObj(o: any): void {
            o.value = this.checkDigital(o.value);
        }

        /**
         * 非法字符校验
         * @param value
         * @returns {string}
         */
        checkIllegalCharacter(value: any): string {
            value = this.replackCode(value, /[^\dA-Za-z]/g, 0);
            return value;
        }


        /**
         * 非法字符校验
         * @param o
         * @returns {string}
         */
        replaceIllegalCharacter(o: any): void {
            // o.value=o.value.replace(/[^\dA-Za-z]/g, "");
            // o.value=o.value.substr(0,20);
            this.replaceCodeCommon(o, /[^\dA-Za-z]/g, 20);
        }

        /**
         * 非法字符校验
         * @param o
         * @returns {string}
         */
        replaceIllegalCharacter2(o: any): void {
            // o.value=o.value.replace(/[^\dA-Za-z]/g, "");
            // o.value=o.value.substr(0,17);
            this.replaceCodeCommon(o, /[^\dA-Za-z]/g, 17);
        }

        /**
         * 限制车牌号输入非法字符（只能输入汉字、字母、数字）
         * @param o
         */
        replaceLpno(o: any): void {
            var reg = /[^A-Za-z0-9\u4e00-\u9fa5]/g;
            o.value = this.replackCode(o.value, reg, 0);

            //obj.value=obj.value.substr(0,32);
        }

        /**
         *  限制车牌号输入非法字符（只能输入汉字、字母、数字）
         * @param {string} value
         * @returns {string}
         */
        replaceCph(value: string): string {
            value = this.replackCode(value, /[^A-Za-z0-9\u4e00-\u9fa5]/g, 0);
            return value;
        }

        /**
         * 限制文本框输入通用方法
         * 传入大于0时，才会进行截取，否则不截取
         * @param o
         * @param {RegExp} reg
         * @param {number} length
         */
        replaceCodeCommon(o: any, reg: RegExp, length: number) {
            o.value = o.value.replace(reg, '');
            if (length > 0) {
                o.value = o.value.substr(0, length);
            }
        }

        /**
         * 去除html标签
         * @param s string
         */
        replaceHtml(s: string): string {
            return s.replace(/<[^>]+>/g, '')
        }

        /**
         * 克隆一个对象
         * @param obj
         */
        clone(obj: any): any {
            var objClone;
            if (obj.constructor == Object) {
                objClone = new obj.constructor();
            } else {
                objClone = new obj.constructor(obj.valueOf());
            }
            for (var key in obj) {
                if (objClone[key] != obj[key]) {
                    if (typeof (obj[key]) == 'object') {
                        objClone[key] = ns.clone(obj[key]);
                    } else {
                        objClone[key] = obj[key];
                    }
                }
            }
            objClone.toString = obj.toString;
            objClone.valueOf = obj.valueOf;

            return objClone;
        }

        /**
         * 数组去重
         * @param arr {string}
         * @return {string}
         */
        arrUnique(arr: string[]): string[] {
            var n: { [index: string]: boolean } = {}, r: string[] = []; //n为hash表，r为临时数组
            for (var i = 0; i < arr.length; i++) //遍历当前数组
            {
                if (!n[arr[i]]) //如果hash表中没有当前项
                {
                    n[arr[i]] = true; //存入hash表
                    r.push(arr[i]); //把当前数组的当前项push到临时数组里面
                }
            }
            return r;
        }

        /**
         * 格式化日期
         * @param date {Data}
         * @param fmt {string}
         * @example dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss.S") ==> 2014-01-06 08:09:04.423
         * @example dateFormat(new Date(), "yyyy/M/d") ==> 2014/1/6
         */
        dateFormat(date: Date, fmt: string): string {
            // 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
            // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
            var o: { [index: string]: number } = {
                "M+": date.getMonth() + 1, //月份
                "d+": date.getDate(), //日
                "h+": date.getHours(), //小时
                "m+": date.getMinutes(), //分
                "s+": date.getSeconds(), //秒
                "q+": Math.floor((date.getMonth() + 3) / 3), //季度
                "S": date.getMilliseconds() //毫秒
            };
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
            }
            for (var k in o) {
                if (new RegExp("(" + k + ")").test(fmt)) {
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k].toString()) : (("00" + o[k]).substr(("" + o[k]).length)));
                }
            }

            return fmt;
        }

        /**
         * 省份选取通用UI字符串
         * @param province {JQuery} 省份select
         * @param allTxt {string}
         */
        getProvinceListStr(allTxt?: string): string {
            allTxt = typeof allTxt == "undefined" ? "请选择" : allTxt;
            let optionStr = '<option value="">' + allTxt + '</option>';

            let provinceList = ns.dict.getProvinceList();
            if (ns.isEmpty(provinceList)) {
                return optionStr;
            }

            for (let i = 0; i < provinceList.length; i++) {
                optionStr += '<option value="' + provinceList[i].districtId + '">' + provinceList[i].districtName + '</option>';
            }

            return optionStr;
        }

        /**
         * 省份选取通用UI
         * @param province {JQuery} 省份select
         * @param allTxt {string}
         */
        getProvinceList(province: string, allTxt?: string): void {
            // var provinceList = ns.dict.getProvinceList();
            // if (ns.isEmpty(provinceList)) {
            //     return;
            // }
            //
            // allTxt = typeof allTxt == "undefined" ? "请选择" : allTxt;
            // var optionStr = '<option value="">' + allTxt + '</option>';
            // for (var i = 0; i < provinceList.length; i++) {
            //     optionStr += '<option value="' + provinceList[i].districtId + '">' + provinceList[i].districtName + '</option>';
            // }
            //
            // $(province).html(optionStr);
            $(province).html(ns.getProvinceListStr(allTxt));
        }

        /**
         * 根据选择的省份，联动获取城市数据
         * @param province {string} 省份select
         * @param city {string} 城市select
         * @param allTxt {string}
         */
        changeProvince(province: string, city: string, allTxt?: string): void {
            var provinceId = $(province).val();
            allTxt = typeof allTxt == "undefined" ? "请选择" : allTxt;

            var selectHtml = '<option value="">' + allTxt + '</option>';
            if (ns.isEmpty(provinceId)) {
                selectHtml = '<option value="">' + allTxt + '</option>';
                $(city).html(selectHtml);
                return;
            }
            var cityList = ns.dict.getCityList(provinceId);
            /*if(isEmpty(cityList)){
                return;
            }*/
            for (var i = 0; i < cityList.length; i++) {
                selectHtml += '<option value="' + cityList[i].districtId + '">' + cityList[i].districtName + '</option>';
            }
            $(city).html(selectHtml);
        }

        /**
         * 根据选择的城市，联动获取区县数据
         * @param city {string}
         * @param country {string}
         */
        changeCity(city: string, country: string): void {
            var cityId = $(city).val();
            var selectHtml = '<option value="">所属区域</option>';
            if (ns.isEmpty(cityId)) {
                $(city).html(selectHtml);
                return;
            }
            var countryList = ns.dict.getCountryList(cityId);
            if (ns.isEmpty(countryList)) {
                return;
            }
            for (var i = 0; i < countryList.length; i++) {
                selectHtml += '<option value="' + countryList[i].countyId + '">' + countryList[i].countyName + '</option>';
            }
            $(country).html(selectHtml);
        }

        /**
         * checkbox全选和取消全选操作
         * @param wrap1 {any}
         * @param wrap2 {any}
         */
        checkAll(wrap1: any, wrap2: any) {
            $(document).on('click', wrap1, function () {
                if ($(wrap1).length == $(wrap1 + ":checked").length) {
                    $(wrap2).attr("checked", "checked");
                } else {
                    $(wrap2).removeAttr("checked");
                }
            });
            $(document).on('click', wrap2, function () {
                if ($(wrap2).length != 0 && $(wrap2).length == $(wrap2 + ":checked").length) {
                    $(wrap1).attr("checked", "checked");
                } else {
                    $(wrap1).removeAttr("checked");
                }
            });
        }

        /**
         * 获取url参数方法
         * @return {object}
         */
        getRequestParam(): object {
            var url = location.search;//获取url中"?"符后的字串
            var theRequest: { [index: string]: string } = {};

            if (url.indexOf("?") != -1) {
                var str = url.substr(1);
                var strs = str.split("&");

                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
                }
            }

            return theRequest;
        }

        /**
         * 初始化翻页控件
         * @param pageId {string}
         * @param callFunc  翻页查询方法
         * @param data
         * @param onePageNum {string} 翻页单页数据属性名
         */
        paging(pageId: string, callFunc: (a: number, b: string) => void, data: any, onePageNum: string): void {

            let $pageId = $("#" + pageId);
            $pageId.find("span[name='totalCoutnCount']").html(data.detail.totalRecordNum);
            $pageId.find("span[name='showPageCount']").html(data.detail.pages);
            $pageId.find("input[name='pageNo']").val(data.detail.pageNo);
            $pageId.find("span[name='pageCount']").val(data.detail.pages);
            $pageId.find("input[name='nowPage']").val(data.detail.pageNo);

            //绑定事件
            $pageId.find("select[name='pageSize']").unbind().on("change", function (this: This) {
                //_data对象为页面内对象，仅卡嘉js界面有，所有ts界面内均无，故需增加undefined判断
                (typeof __data !== "undefined") && __data && __data[onePageNum] && (__data[onePageNum] = this.value);
                callFunc(1, "search");
            });

            $pageId.find("img[name='firstPage']").unbind().on("click", function () {
                callFunc(1, "search");
            });

            $pageId.find("img[name='prePage']").unbind().on("click", function () {
                if (data.detail.pageNo == 1) {
                    return false;
                } else {
                    callFunc(data.detail.pageNo - 1, "search");
                }

            });

            $pageId.find("img[name='nextPage']").unbind().on("click", function () {
                if (data.detail.pageNo == data.detail.pages) {
                    return false;
                } else {
                    callFunc(data.detail.pageNo + 1, "search");
                }
            });

            $pageId.find("img[name='endPage']").unbind().on("click", function () {
                callFunc(data.detail.pages, "search");
            });

            $pageId.find("input[name='nowPage']").unbind().on("keyup", function (this: This) {
                if (!ns.checkIntegerValid(this.value) || parseInt(this.value) > data.detail.pages) {
                    $(this).val(data.detail.pages);
                    return false;
                }

                callFunc(parseInt(this.value), "search");
            });
        }

        /**
         *
         * @param result
         */
        checkUserIsLogin(result: number): void {
            if (result == 11 || result == 12) {
                if (ns.uType === "2") {
                    parent.location.href = "../../dyIndex.jsp";
                } else {
                    parent.location.href = "../../index.jsp";
                }
            }
        }

        /**
         * 针对undefined数据处理
         * @param data
         */
        formatUndefined(data: any): any {
            if (typeof data == "undefined") {
                return "";
            }
            else {
                return data;
            }
        }

        /**
         * 字符串转 16进制 ASCII 码
         * @param {string} str
         * @returns {string}
         */
        strToAscii(str: string): string {
            let ascllStr = "";
            let rawStr = this.formatUndefined(str);
            if (typeof rawStr === "string") {
                for (let i = 0; i < rawStr.length; i++) {
                    ascllStr += str.charCodeAt(i).toString(16).toUpperCase();
                }
            }
            else {
                console.log("it is not a string");
            }
            return ascllStr;
        }

        /**
         * 判断数据是否为空
         * @param data
         * @returns {boolean}
         */
        isNull(data: any): boolean {
            return (typeof data === "undefined") || (data == null);
        }

        /**
         * 判断字符串是否不为空
         */
        isNotNull(data: any): boolean {
            return !this.isNull(data);
        }

        /**
         * 初始化系统
         */
        init(): void {
            //获取地址栏请求参数,并以键值对的方式存入
            ns.requestParam = ns.getRequestParam();
            //初始化数据
            ns.initPageData();
            //初始化页面数据
            ns.initDom();
            //一、二级菜单有效性检查
            ns.checkUserMenuPrivilege();
            //初始化二级菜单显示
            // ns.initSubMenu();
            //初始化事件
            //initPageEvent();
            //dialog
            //initDialog();
        }


        initPageData(): void {
            ns.corpId = window.parent.ns.corpId;
            ns.deptId = window.parent.ns.deptId;
            ns.userId = window.parent.ns.userId;
            ns.userType = window.parent.ns.userType;
            ns.sessionId = window.parent.ns.sessionId;
            ns.uType = window.parent.ns.uType;
        }

        initDom(): void {
        }

        /**
         * 检查用户当前菜单是否有效
         */
        checkUserMenuPrivilege(): void {

            if (!ns.checkMenuPrivilege(ns.menuId, ns.subMenuId)) {
                if (ns.uType === "2") {
                    parent.location.href = "../../dyIndex.jsp";
                } else {
                    parent.location.href = "../../index.jsp";
                }
            }
        }

        /**
         * 检查 一二级菜单是否有效
         * @param {string} menuId
         * @param {string} subMenuId
         * @returns {boolean}
         */
        checkMenuPrivilege(menuId: string, subMenuId: string): boolean {
            let firstLevel = false;
            let secondLevel = false;

            let subMenuObj = ns.subMenuObj != null ? ns.subMenuObj : JSON.parse(localStorage["subMenuObj"]);
            if (typeof subMenuObj[menuId] !== null) {
                firstLevel = true;
                let subMenu = subMenuObj[menuId];
                if (typeof subMenu !== "undefined") {
                    for (let i = 0; i < subMenu.length; i++) {
                        if (subMenu[i].menuId === ns.subMenuId) {
                            secondLevel = true;
                            break;
                        }
                    }
                }
            }

            //由于车辆监控和系统设置，统计报表没有二级菜单id的，默认二级为true
            if (typeof ns.subMenuId == "undefined") {
                secondLevel = true;
            }

            return firstLevel && secondLevel;
        }

        initSubMenu(): void {
            //统计分析，标签
            let basicMenu = [];
            let vehCorpMenu = [];
            let serviceStationMenu = [];
            let vehCorpBusinessMenu = [];
            let dyvehOperationMenu = [];
            //系统管理标签
            let businessMenu = [];
            let standardSixMenu = [];
            let g5Menu = [];
            let pfLandmarkMenu = [];
            let sysMenu = [];
            let infoMenu = [];
            let fileMenu = [];
            let iccidMenu = [];
            //日志管理
            let logMenu = [];
            //风险防控标签
            // var basicMenu = [];
            //数据分析
            let dataStatisticMenu = [];

            if (typeof ns.subMenus[ns.menuId] === "undefined") {
                //加载所有子菜单
                let subMenuObj = ns.subMenuObj != null ? ns.subMenuObj : JSON.parse(localStorage["subMenuObj"]);
                if (typeof subMenuObj[ns.menuId] !== "undefined") {
                    //TODO 判断是否需要查询子菜单（存储子菜单为空则查）
                    let subList = subMenuObj[ns.menuId];
                    if (ns.uType == "2") {
                        //循环大运菜单
                        switch (ns.menuId) {
                            case "dy_menu_1":
                                for (let i = 0; i < subList.length; i++) {
                                    switch (subList[i].menuId) {
                                        case "dy_menu_1_0":
                                        case "dy_menu_1_4":
                                        case "dy_menu_1_5":
                                        case "dy_menu_1_6":
                                        case "dy_menu_1_7":
                                        case "dy_menu_1_8":
                                            basicMenu.push(subList[i]);
                                            break;
                                        case "dy_menu_1_1":
                                        case "dy_menu_1_2":
                                        case "dy_menu_1_3":
                                            dyvehOperationMenu.push(subList[i]);
                                            break;
                                        default:
                                            console.warn("menu:" + subList[i].menuId + " is unKnown!");
                                            break;
                                    }
                                }

                                ns.subMenus[ns.menuId] =
                                    {
                                        "basicMenu": basicMenu,
                                        "dyvehOperationMenu": dyvehOperationMenu
                                    };
                                break;
                            case "dy_menu_2":
                                for (let i = 0; i < subList.length; i++) {
                                    switch (subList[i].menuId) {
                                        //销售区域管理
                                        case "dy_menu_2_0":
                                        //经销商管理
                                        case "dy_menu_2_1":
                                        //车辆管理
                                        case "dy_menu_2_2":
                                        //电机型号管理
                                        case "dy_menu_2_3":
                                        //车辆转发
                                        case "dy_menu_2_4":
                                            businessMenu.push(subList[i]);
                                            break;
                                        //用户管理
                                        case "dy_menu_2_5":
                                        //角色管理
                                        case "dy_menu_2_6":
                                            sysMenu.push(subList[i]);
                                            break;
                                        //系统日志
                                        case "dy_menu_2_7":
                                        //指令日志
                                        case "dy_menu_2_8":
                                            logMenu.push(subList[i]);
                                            break;
                                        default:
                                            console.warn("menu:" + subList[i].menuId + " is unKnown!");
                                            break;
                                    }
                                }
                                ns.subMenus[ns.menuId] =
                                    {
                                        "businessMenu": businessMenu,
                                        "sysMenu": sysMenu,
                                        "logMenu": logMenu
                                    };
                                break;
                            default:
                                break;
                        }
                    }
                    else {
                        switch (ns.menuId) {
                            case "kj_menu_1":
                                for (let i = 0; i < subList.length; i++) {

                                    switch (subList[i].menuId) {
                                        case "kj_menu_1_1":
                                        case "kj_menu_1_2":
                                        case "kj_menu_1_8":
                                        case "kj_menu_1_7":
                                        case "kj_menu_1_9":
                                        case "kj_menu_1_15":
                                        case "kj_menu_1_16":
                                        case "kj_menu_1_17":
                                        case "kj_menu_1_18":
                                        case "kj_menu_1_19":
                                        case "kj_menu_1_20":
                                        case "kj_menu_1_21":
                                        case "kj_menu_1_22":
                                            //统计分析 通用报表
                                            basicMenu.push(subList[i]);
                                            break;
                                        case  "kj_menu_1_0":
                                        case  "kj_menu_1_11":
                                        case  "kj_menu_1_12":
                                        case  "kj_menu_1_13":
                                        case  "kj_menu_1_14":
                                            //统计分析 车厂产销报表
                                            vehCorpMenu.push(subList[i]);
                                            break;
                                        case "kj_menu_1_3":
                                        case "kj_menu_1_4":
                                        case "kj_menu_1_5":
                                            //统计分析 服务站报表
                                            serviceStationMenu.push(subList[i]);
                                            break;
                                        case "kj_menu_1_6":
                                        case "kj_menu_1_10":
                                            //统计分析 车厂业务报表
                                            vehCorpBusinessMenu.push(subList[i]);
                                            break;
                                    }
                                }
                                ns.subMenus[ns.menuId] =
                                    {
                                        "basicMenu": basicMenu,
                                        "vehCorpMenu": vehCorpMenu,
                                        "serviceStationMenu": serviceStationMenu,
                                        "vehCorpBusinessMenu": vehCorpBusinessMenu
                                    };
                                break;
                            case "kj_menu_2":
                                for (let i = 0; i < subList.length; i++) {
                                    switch (subList[i].menuId) {
                                        case "kj_menu_2_0":
                                        case "kj_menu_2_1":
                                        case "kj_menu_2_2":
                                        case "kj_menu_2_3":
                                        case "kj_menu_2_4":
                                        case "kj_menu_2_7":
                                        case "kj_menu_2_8":
                                        case "kj_menu_2_12":
                                        case "kj_menu_2_37":
                                            //系统管理 业务相关
                                            businessMenu.push(subList[i]);
                                            break;
                                        case "kj_menu_2_16":
                                        case "kj_menu_2_17":
                                        case "kj_menu_2_18":
                                        case "kj_menu_2_19":
                                            //系统管理 物联网卡管理
                                            iccidMenu.push(subList[i]);
                                            break;
                                        case "kj_menu_2_30":
                                        case "kj_menu_2_31":
                                        case "kj_menu_2_32":
                                        case "kj_menu_2_33":
                                        case "kj_menu_2_34":
                                            //系统管理 国六相关
                                            standardSixMenu.push(subList[i]);
                                            break;
                                        case "kj_menu_2_38":
                                            g5Menu.push(subList[i]);
                                            break;
                                        case "kj_menu_2_39":
                                            pfLandmarkMenu.push(subList[i]);
                                            break;
                                        case "kj_menu_2_9":
                                        case "kj_menu_2_10":
                                        case "kj_menu_2_11":
                                            //系统管理 信息管理
                                            infoMenu.push(subList[i]);
                                            break;
                                        case "kj_menu_2_35":
                                        case "kj_menu_2_36":
                                            //系统管理 文件相关
                                            fileMenu.push(subList[i]);
                                            break;
                                        case "kj_menu_2_5":
                                        case "kj_menu_2_6":
                                            //系统管理 系统相关
                                            sysMenu.push(subList[i]);

                                            break;
                                    }
                                }
                                ns.subMenus[ns.menuId] =
                                    {
                                        "businessMenu": businessMenu,
                                        "iccidMenu": iccidMenu,
                                        "standardSixMenu": standardSixMenu,
                                        "g5Menu": g5Menu,
                                        "pfLandmarkMenu": pfLandmarkMenu,
                                        "infoMenu": infoMenu,
                                        "fileMenu": fileMenu,
                                        "sysMenu": sysMenu
                                    };
                                break;
                            case "kj_menu_3":
                                for (let i = 0; i < subList.length; i++) {

                                    switch (subList[i].menuId) {
                                        case "kj_menu_3_0":
                                        case "kj_menu_3_1":
                                        case "kj_menu_3_2":
                                        case "kj_menu_3_3":
                                        case "kj_menu_3_4":
                                            basicMenu.push(subList[i]);
                                            break;
                                    }
                                }
                                ns.subMenus[ns.menuId] =
                                    {
                                        "basicMenu": basicMenu,
                                    };
                                break;
                            case "kj_menu_4":
                                for (let i = 0; i < subList.length; i++) {
                                    switch (subList[i].menuId) {
                                        //卡嘉菜单，车辆日上线率统计
                                        case "kj_menu_4_0":
                                        //卡嘉菜单，国六大屏监控
                                        case "kj_menu_4_1":
                                        //卡嘉菜单，江淮海量点看板
                                        case "kj_menu_4_2":
                                            //卡嘉菜单，江淮热力图看板
                                            //case "kj_menu_4_3":
                                            dataStatisticMenu.push(subList[i]);
                                            break;
                                    }
                                }
                                ns.subMenus[ns.menuId] =
                                    {
                                        "dataStatisticMenu": dataStatisticMenu,
                                    };
                                break;
                            default:
                                break;
                        }
                    }
                }
                else {
                    console.warn("menuId" + ns.menuId + " is unKnown!");
                    return;
                }
            }

            let menus = ns.subMenus[ns.menuId];
            for (let x in menus) {
                let menuListId = x + "List";
                ns.loadMenu(menus[x], $("#" + menuListId));
            }

        }

        /**
         * 加载菜单方法
         * @param data 菜单数组
         * @param $menuEle 填入的jquery元素
         */
        loadMenu(data: Array<string>, $menuEle: JQuery): void {
            if (data.length > 0) {
                $menuEle.html(template.render('subMenuListGen', {"subMenu": data}));
            } else {
                $menuEle.parent().remove();
            }
        }

        /**
         * 根据用户访问的平台类型、一级菜单编号，加载对应的一级菜单
         * @Author add by ywwh 187 zhangli
         * @param {number} num
         * @returns {string}
         */
        switchFirstLevelMenu(num: number): void {
            this.switchLevelMenu(num, null);
        }

        /**
         * 根据用户 和 菜单编号 加载对应的菜单,
         * @param {number} lve1 一级菜单编号
         * @param {any} lve2 二级菜单编号(可为空)
         * @returns void
         */
        switchLevelMenu(lve1: number, lve2: any): void {
            //初始化一级菜单前缀
            let menuPrefix = ["", "kj", "dy"];
            if (this.uType == null) {
                this.uType = "2";
            }
            this.menuId = menuPrefix[parseInt(this.uType)] + "_menu_" + lve1;

            if (typeof lve2 !== "undefined" && lve2 != null) {
                this.subMenuId = this.menuId + "_" + lve2;
            }
        }

        initPageEvent(menuId: string): void {
            $(window.parent).on("resize", function () {
                if (menuId.indexOf("menu_0") >= 0) {
                    let sysTopHeight = $('.head').height();
                    var sysHtmlHeight = document.documentElement.clientHeight;
                    $(".vehicleTabCon,.ServiceTabCon,.RiskPreventTabCon").css({
                        "height": sysHtmlHeight - sysTopHeight - 70,
                    });
                    $(".mainRight").css({
                        "height": sysHtmlHeight - sysTopHeight - 72 + 25,
                    });
                } else {
                    //初始化dom高度
                    $(".mainContant").eq(0).height($(window).height() - 13);
                    $(".statisRightCont").eq(0).height($(".mainContant").height() - 10)
                    $(".statisLeftNav").eq(0).height($(".mainContant").height() - 10)

                    var sysRightCh = $('.statisRightCont').height();
                    var sysRightTabH = $('.sellStateQuery').outerHeight();
                    $(".sellStateResult").css({
                        "height": sysRightCh - sysRightTabH - 72,
                        "overflow": "auto"
                    });
                }
            })
        }

        /**
         * 获取大运基础数据列表
         * @param {number} type 基础数据类型
         * @returns {string}
         */
        dyGetBasicData(type: number): string {
            var par = {
                dataType: type
            };
            var result = "";
            ns.ajaxSync("getBasicData", par, function (data) {
                if (data.result != 0) {
                    return;
                }
                var dataObj = data.detail.dataList;

                for (var i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i].dataCode + "'>" + dataObj[i].dataValue + "</option>";
                }
            });
            return result;
        }

        initDialog(): void {

        }

        /**
         * 读取导入文件信息,检查格式，不通过则返回空，提示检查结果，否则返回数据列表
         * 读取文件内容，提示存在无效内容文件，返回文件结果
         * @param file  待读取的文件
         * @param XLSX  xlsx 对象
         * @param templateObj   读取模板
         * @param callback  回调函数（用来传递返回值）
         * @param errorCallback  文件检测异常的回调函数
         * @returns {boolean}   读取结果
         */
        loadImportFileNew(file: any, XLSX: any, templateObj: any, callback: any, errorCallback: any): boolean {
            if( "undefined" === typeof file )
            {
                return false;
            }

            let reader = new FileReader();
            //内容大于1M就提示
            let note = "内容格式分析中 请稍后...";
            if (file.size > 2001) {
                note = "文件较大," + note;
                if (file.size > 20000) {
                    $.jBox.tip("文件大小超过限制：仅允许一次导入2k行", 'warning');
                    return false;
                }
            }
            $.jBox.tip(note, 'loading');
            var rABS = typeof FileReader !== "undefined" && (FileReader.prototype || {}).readAsBinaryString;
            //设置读取结果加载方法
            reader.onload = function (e: any) {
                let data = e.target.result;
                //workbook存放excel的所有基本信息
                let workbook = XLSX.read(data, {type: rABS ? 'binary' : 'array'});
                //定义sheetList中存放excel表格的sheet表，就是最下方的tab
                let sheetList = workbook.SheetNames;

                //存放json数组格式的表格数据
                var json: any[] = [];
                sheetList.forEach(function (y: any) {
                    // let csv = XLSX.utils.sheet_to_csv(workbook.Sheets[y]);
                    let obj = XLSX.utils.sheet_to_json(workbook.Sheets[y]);
                    if (obj.length > 0) {
                        //暂时不考虑多sheet
                        json = obj;
                    }
                });

                let dataList = [];
                let error: any = {};
                if (json != null && json.length > 0) {
                    if (json.length > 2001) {
                        $.jBox.tip("文件大小超过限制：仅允许一次导入2k行", 'warning');
                        return false;
                    }
                    let errorFlag = false;
                    let columns: any = {};
                    for (let i = 0; i < json.length; i++) {
                        let jsonObj = json[i];
                        let dataObj: any = {};
                        for (let par in templateObj) {
                            let value = jsonObj[par];
                            let template = templateObj[par];
                            if (typeof template.validCheck === "function" && !template.validCheck(value)) {
                                //校验失败，记录行数、错误原因
                                error.number = i + 1;
                                error.note = par + template.errorNote;
                                errorFlag = true;
                                break;
                            }
                            else {
                                if( value !== "" )
                                {
                                    if( typeof columns[template.name] === "undefined")
                                    {
                                        columns[template.name] = [];
                                        columns[template.name][value] = 1;
                                    }
                                    else
                                    {
                                        if( typeof columns[template.name][value] !== "undefined")
                                        {
                                            errorFlag = true;
                                            error.number = i + 1;
                                            error.note = par + " 存在重复值 " + value;
                                            break;
                                        }
                                    }
                                }
                                dataObj[template.name] = value;
                            }
                        }
                        //检测到异常行，直接退出分析
                        if (errorFlag) {
                            break;
                        }
                        dataList.push(dataObj);
                    }

                    $.jBox.closeTip();
                    if (errorFlag) {
                        // 错误发生，重置导入框
                        if( typeof errorCallback === "function")
                        {
                            errorCallback(error);
                        }
                        return false;
                    }
                    else {
                        console.log("sheet1", dataList);
                        // ns.batchImport(dataList, importUrl);
                    }
                }
                else
                {
                    $.jBox.tip("无有效数据行，请选择有效文件重新导入", 'warning');
                    return false;
                }
                if( typeof callback === "function")
                {
                    callback(dataList);
                }
                // B.batchImport(resultJson);
                return true;

            };
            //监听读取异常
            reader.onerror = function (evt: any) {
                switch (evt.target.error.code) {
                    case evt.target.error.NOT_FOUND_ERR:
                        alert('File Not Found!');
                        break;
                    case evt.target.error.NOT_READABLE_ERR:
                        alert('File is not readable');
                        break;
                    //接收指定终止
                    case evt.target.error.ABORT_ERR:
                        break; // noop
                    default:
                        alert('An error occurred reading this file.');
                }
            };
            //开始读取分析内容
            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);

            return false;
        }

        /**
         * 读取导入文件信息
         * 读取文件内容，提示存在无效内容文件，返回文件结果
         * @deprecated 由上面带回调的方法 loadImportFileNew 代替
         */
        loadImportFile(file: any, XLSX: any, templateObj: any, importUrl: string): boolean {
            //重新加载导入文件后，重置异常信息
            ns.failInfo = [];
            if( "undefined" === typeof file )
            {
                return false;
            }

            let reader = new FileReader();
            //内容大于1M就提示
            let note = "内容格式分析中 请稍后...";
            if (file.size > 1000000) {
                note = "文件较大," + note;
                if (file.size > 2000000) {
                    $.jBox.tip("文件大小超过限制：2M", 'warning');
                    return false;
                }
            }
            $.jBox.tip(note, 'loading');
            var rABS = typeof FileReader !== "undefined" && (FileReader.prototype || {}).readAsBinaryString;
            //设置读取结果加载方法
            reader.onload = function (e: any) {
                let data = e.target.result;
                //workbook存放excel的所有基本信息
                let workbook = XLSX.read(data, {type: rABS ? 'binary' : 'array'});
                //定义sheetList中存放excel表格的sheet表，就是最下方的tab
                let sheetList = workbook.SheetNames;

                //存放json数组格式的表格数据
                var json: any[] = [];
                sheetList.forEach(function (y: any) {
                    // let csv = XLSX.utils.sheet_to_csv(workbook.Sheets[y]);
                    let obj = XLSX.utils.sheet_to_json(workbook.Sheets[y]);
                    if (obj.length > 0) {
                        //暂时不考虑多sheet
                        json = obj;
                    }
                });

                let dataList = [];
                let error: any = {};
                if (json != null && json.length > 0) {
                    let errorFlag = false;
                    for (let i = 0; i < json.length; i++) {
                        let jsonObj = json[i];
                        let dataObj: any = {};
                        for (let par in templateObj) {
                            let value = jsonObj[par];
                            let template = templateObj[par];
                            if (par === "城市") {
                                let province = {value: jsonObj["省份"]};
                                let existFLag = false;
                                if (templateObj["省份"].validCheck(province)) {
                                    let cityList = ns.dict.getCityList(province.value);
                                    for (let i = 0; i < cityList.length; i++) {
                                        if (value === cityList[i].districtName) {
                                            dataObj[template.name] = cityList[i].districtId;
                                            existFLag = true;
                                            break;
                                        }
                                    }
                                }
                                else {
                                    template = templateObj["省份"];
                                }

                                if (!existFLag) {
                                    error.number = i + 1;
                                    error.note = par + template.errorNote;
                                    errorFlag = true;
                                    break;
                                }
                            }
                            else if (par === "省份") {
                                let province = {value: value};
                                if (template.validCheck(province)) {
                                    dataObj[template.name] = province.value;
                                }
                                else {
                                    error.number = i + 1;
                                    error.note = par + template.errorNote;
                                    errorFlag = true;
                                    break;
                                }
                            }
                            else if (typeof template.validCheck === "function" && !template.validCheck(value)) {
                                //校验失败，记录行数、错误原因
                                error.number = i + 1;
                                error.note = par + template.errorNote;
                                errorFlag = true;
                                break;
                            }
                            else {
                                dataObj[template.name] = value;
                            }
                        }
                        //检测到异常行，直接退出分析
                        if (errorFlag) {
                            break;
                        }
                        dataList.push(dataObj);
                    }

                    if (errorFlag) {
                        $.jBox.closeTip();
                        // 错误发生，重置导入框
                        ns.resetBatchImport();
                        let errorNote = "文件内容有误，第" + error.number + "行：" + error.note + "，请调整后重新导入";
                        $.jBox.tip(errorNote, "warning");
                        $("#failInfo")[0].innerText = errorNote;
                        return false;
                    }
                    else {
                        //数据基本检测无误，则直接导入，否则：生成异常提示文件
                        $.jBox.closeTip();
                        console.log("sheet1", dataList);
                        ns.batchImport(dataList, importUrl);
                    }
                }
                // B.batchImport(resultJson);
                return true;

            };
            //监听读取异常
            reader.onerror = function (evt: any) {
                switch (evt.target.error.code) {
                    case evt.target.error.NOT_FOUND_ERR:
                        alert('File Not Found!');
                        break;
                    case evt.target.error.NOT_READABLE_ERR:
                        alert('File is not readable');
                        break;
                    //接收指定终止
                    case evt.target.error.ABORT_ERR:
                        break; // noop
                    default:
                        alert('An error occurred reading this file.');
                }
            };
            //开始读取分析内容
            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);

            return true;
        }

        resetBatchImport() {
            //清空 提示区域
            $("#failInfo").empty();
            //清空导入文件
            $("#xlf").val("");
        }

        /**
         * 批量导入方法："batchInsertAgency"
         * @param data
         * @param {string} importUrl
         */
        batchImport(data:any,importUrl:string)
        {
            if( data == null || data.length === 0 )
            {
                $.jBox.tip("请先导入有效文件", 'warning');
                $("#xlf").focus();
                return;
            }

            $.jBox.tip("正在批量插入, 请稍后...", 'loading');
            ns.ajaxSync(importUrl, {"dataList": data}, function (data) {
                $.jBox.closeTip();
                if (data.result !== 0) {
                    $.jBox.tip(data.resultNote, 'error');
                    return false;
                }
                let result = data.detail;
                let resultNote = "批量导入结束，";
                let resultLevel = "success";
                if (typeof result.successNum !== "undefined") {
                    resultNote = resultNote + "成功数量：" + result.successNum + " ";
                }

                if (typeof result.errorList !== "undefined") {
                    resultNote = resultNote + "失败数量：" + result.errorList.length + "，请点击下方\"失败信息导出\"按钮，导出失败原因";
                    resultLevel = "error";

                    //存在导入失败的情况，生成 失败导出文件
                    $("#failInfo").append("<input type=\"button\" id=\"failInfoExport\" value=\"失败信息导出\" />");
                    __data.failInfo = result.errorList;
                    $("#failInfoExport").on('click', ns.exportFailInfoFile);
                }

                $.jBox.tip(resultNote, resultLevel);
            });
        }

        exportFailInfoFile() {
            let dataList = __data.failInfo;
            if (dataList == null && dataList.length === 0) {
                $.jBox.tip("当前无导入失败信息", 'warning');
                return;
            }
            let excel = "失败行数,"
                + "失败原因\n";

            for (let i = 0; i < dataList.length; i++) {
                excel += (dataList[i].number + 1) + ","
                    + ns.formatUndefined(dataList[i].error) + "\n";
            }

            let exportContent = "\uFEFF";
            let blob = new Blob([exportContent + excel], {type: "text/plain;charset=utf-8"});
            saveAs(blob, "importErrorInfo_" + ns.dateFormat(new Date(), "yyyyMMddhhmmss") + ".csv");
        }
    }

    /**
     * 车辆状态
     */
    export enum CarStatus
    {
        ONLINE_RUN_NONE = "在线行驶不报警",
        ONLINE_STOP_NONE = "在线停车不报警",
        ONLINE_NONE_NONE = "在线不定位不报警",
        ONLINE_RUN_ALARM = "在线行驶报警",
        ONLINE_STOP_ALARM = "在线停车报警",
        ONLINE_NONE_ALARM = "在线不定位报警",
        OFFLINE_RUN_NONE = "不在线行驶",
        OFFLINE_STOP_NONE = "不在线停车",
        OFFLINE_NONE_NONE = "不在线不定位",

    }

    /***
     * 解锁车操作枚举常量：0申请、1受理、2处理
     */
    export enum UnlockCarOpr
    {
        APPLY = 0,
        ACCEPT = 1,
        HANDLE = 2
    }

    /**
     * 应答对象 目前专用于 FUNC.commonResultCheck
     * status 应答结果
     * resultNote 应答描述 可能存在
     */
    interface ResultObj {
        status : number,
        resultJson : any|null,
        resultNote ?: string
    }

    /**
     * ts 参数对象（包含任意类型、个数的参数）
     */
    export interface ParamObj {
        [x: string]: any;
    }

    export class DICT {

        /**
         * 防控类型 解锁车业务字典
         */
        BusinessTypes : any;
        industryType : any;
        epaVehicleType : any;
        transVehicleType: any;
        emissionLevelType: any;
        alarmType: any;

        constructor() {
            this.alarmType = [
                {"id":"1", "type": "温度差异报警"},
                {"id":"2", "type": "电池高温报警"},
                {"id":"3", "type": "车载储能装置类型过压报警"},
                {"id":"4", "type": "车载储能装置类型欠压报警"},
                {"id":"5", "type": "SOC低报警"},
                {"id":"6", "type": "单体蓄电池过压报警"},
                {"id":"7", "type": "单体蓄电池欠压报警"},
                {"id":"8", "type": "SOC过高报警"},
                {"id":"9", "type": "SOC跳变报警"},
                {"id":"10", "type": "可充电储能系统不匹配报警"},
                {"id":"11", "type": "电池单体一致性差报警"},
                {"id":"12", "type": "绝缘报警"},
                {"id":"13", "type": "DC-DC温度报警"},
                {"id":"14", "type": "制动系统报警"},
                {"id":"15", "type": "DC-DC状态报警"},
                {"id":"16", "type": "驱动电机控制器温度报警"},
                {"id":"17", "type": "高压互锁状态报警"},
                {"id":"18", "type": "驱动电机温度报警"},
                {"id":"19", "type": "车载储能装置类型过充"}
            ];

            this.BusinessTypes = [
                {"id": "101", "zh": "8F40（激活）车辆控制", "en": "8F40 (activated) vehicle control"},
                {"id": "102", "zh": "8F40（关闭）车辆控制", "en": "8F40 (closed) vehicle control"},
                {"id": "103", "zh": "8F40（锁车）车辆控制", "en": "8F40 (Car Lock) Vehicle Control"},
                {"id": "104", "zh": "8F40（解锁）车辆控制", "en": "8F40 (Unlock) Vehicle Control"},
                {"id": "105", "zh": "8F40（解锁并取消激活）车辆控制", "en": "8F40 (Unlock and Deactivate) Vehicle Control"}
            ];

            this.emissionLevelType = [
                {"id":1, "type": "国六"},
                {"id":2, "type": "国五"},
                {"id":3, "type": "国四"},
                {"id":4, "type": "国三"},
                {"id":5, "type": "国二"},
                {"id":6, "type": "排气后处理系统改装车辆"}
            ];

            this.transVehicleType = [
                {"id":0, "type": "未知"},
                {"id":1, "type": "大型载客车辆"},
                {"id":2, "type": "重型载客车辆"},
                {"id":3, "type": "小型载客车辆"},
                {"id":4, "type": "微型载客车辆"},
                {"id":11, "type":"重型载货车辆"},
                {"id":12, "type":"中型载货车辆"},
                {"id":13, "type":"轻型载货车辆"},
                {"id":14, "type":"微型载货车辆"}

            ];

            this.epaVehicleType = [
                {"id":0, "type": "轻型汽油车"},
                {"id":1, "type": "轻型柴油车"},
                {"id":2, "type": "轻型燃气车"},
                {"id":3, "type": "轻型两用燃料车"},
                {"id":4, "type": "重型柴油车"},
                {"id":5, "type": "重型燃气车"},
                {"id":6, "type": "重型两用燃料车"},
                {"id":10, "type": "轻型汽油混合动力车"},
                {"id":11, "type": "轻型柴油混合动力车"},
                {"id":12, "type": "轻型燃气混合动力车"},
                {"id":13, "type": "轻型两用燃料混合动力车"},
                {"id":14, "type": "重型柴油混合动力车"},
                {"id":15, "type": "重型燃气混合动力车"},
                {"id":16, "type": "重型两用燃料混合动力车"}

            ];

            this.industryType = [
                {"id":"01", "type": "农业"},
                {"id":"02", "type": "林业"},
                {"id":"03", "type": "畜牧业"},
                {"id":"04", "type": "渔业"},
                {"id":"05", "type": "农、林、牧、渔专业及辅助性活动"},
                {"id":"06", "type": "煤炭开采和洗选业"},
                {"id":"07", "type": "石油和天然气开采业"},
                {"id":"08", "type": "黑色金属矿采选业"},
                {"id":"09", "type": "有色金属矿采选业"},
                {"id":"10", "type": "非金属矿采选业"},
                {"id":"11", "type": "开采专业及辅助性活动"},
                {"id":"12", "type": "其他采矿业"},
                {"id":"13", "type": "农副食品加工业"},
                {"id":"14", "type": "食品制造业"},
                {"id":"15", "type": "酒、饮料和精制茶制造业"},
                {"id":"16", "type": "烟草制品业"},
                {"id":"17", "type": "纺织业"},
                {"id":"18", "type": "纺织服装、服饰业"},
                {"id":"19", "type": "皮革、毛皮、羽毛及其制品和制鞋业"},
                {"id":"20", "type": "木材加工和木、竹、藤、棕、草制品业"},
                {"id":"21", "type": "家具制造业"},
                {"id":"22", "type": "造纸和纸制品业"},
                {"id":"23", "type": "印刷和记录媒介复制业"},
                {"id":"24", "type": "文教、工美、体育和娱乐用品制造业"},
                {"id":"25", "type": "石油、煤炭及其他燃料加工业"},
                {"id":"26", "type": "化学原料和化学制品制造业"},
                {"id":"27", "type": "医药制造业"},
                {"id":"28", "type": "化学纤维制造业"},
                {"id":"29", "type": "橡胶和塑料制品业"},
                {"id":"30", "type": "非金属矿物制品业"},
                {"id":"31", "type": "黑色金属冶炼和压延加工业"},
                {"id":"32", "type": "有色金属冶炼和压延加工业"},
                {"id":"33", "type": "金属制品业"},
                {"id":"34", "type": "通用设备制造业"},
                {"id":"35", "type": "专用设备制造业"},
                {"id":"36", "type": "汽车制造业"},
                {"id":"37", "type": "铁路、船舶、航空航天和其他运输设备制造 "},
                {"id":"38", "type": "电气机械和器材制造业"},
                {"id":"39", "type": "计算机、通信和其他电子设备制造业"},
                {"id":"40", "type": "仪器仪表制造业"},
                {"id":"41", "type": "其他制造业"},
                {"id":"42", "type": "废弃资源综合利用业"},
                {"id":"43", "type": "金属制品、机械和设备修理业"},
                {"id":"44", "type": "电力、热力生产和供应业"},
                {"id":"45", "type": "燃气生产和供应业"},
                {"id":"46", "type": "水的生产和供应业"},
                {"id":"47", "type": "房屋建筑业"},
                {"id":"48", "type": "土木工程建筑业"},
                {"id":"49", "type": "建筑安装业"},
                {"id":"50", "type": "建筑装饰、装修和其他建筑业"},
                {"id":"51", "type": "批发业"},
                {"id":"52", "type": "零售业"},
                {"id":"53", "type": "铁路运输业"},
                {"id":"54", "type": "道路运输业"},
                {"id":"55", "type": "水上运输业"},
                {"id":"56", "type": "航空运输业"},
                {"id":"57", "type": "管道运输业"},
                {"id":"58", "type": "多式联运和运输代理业"},
                {"id":"59", "type": "装卸搬运和仓储业"},
                {"id":"60", "type": "邮政业"},
                {"id":"61", "type": "住宿业"},
                {"id":"62", "type": "餐饮业"},
                {"id":"63", "type": "电信、广播电视和卫星传输服务"},
                {"id":"64", "type": "互联网和相关服务"},
                {"id":"65", "type": "软件和信息技术服务业"},
                {"id":"66", "type": "货币金融服务"},
                {"id":"67", "type": "资本市场服务"},
                {"id":"68", "type": "保险业"},
                {"id":"69", "type": "其他金融业"},
                {"id":"70", "type": "房地产业"},
                {"id":"71", "type": "租赁业"},
                {"id":"72", "type": "商务服务业"},
                {"id":"73", "type": "研究和试验发展"},
                {"id":"74", "type": "专业技术服务业"},
                {"id":"75", "type": "科技推广和应用服务业"},
                {"id":"76", "type": "水利管理业"},
                {"id":"77", "type": "生态保护和环境治理业"},
                {"id":"78", "type": "公共设施管理业"},
                {"id":"79", "type": "土地管理业"},
                {"id":"80", "type": "居民服务业"},
                {"id":"81", "type": "机动车、电子产品和日用产品修理业"},
                {"id":"82", "type": "其他服务业"},
                {"id":"83", "type": "教育"},
                {"id":"84", "type": "卫生"},
                {"id":"85", "type": "社会工作"},
                {"id":"86", "type": "新闻和出版业"},
                {"id":"87", "type": "广播、电视、电影和录音制作业"},
                {"id":"88", "type": "文化艺术业"},
                {"id":"89", "type": "体育"},
                {"id":"90", "type": "娱乐业"},
                {"id":"91", "type": "中国共产党机关"},
                {"id":"92", "type": "国家机构"},
                {"id":"93", "type": "人民政协、民主党派"},
                {"id":"94", "type": "社会保障"},
                {"id":"95", "type": "群众团体、社会团体和其他成员组织"},
                {"id":"96", "type": "基层群众自治组织"},
                {"id":"97", "type": "国际组织"}

            ];
        }

        /**
         * 报表中业务类型展示
         * @param type
         */
        getBusinessTypes(type:string):string{
            if(ns.lang ==="zh"){
                if(type === "101"){
                    return "8F40（激活）车辆控制";
                }else if(type === "102"){
                    return "8F40（关闭）车辆控制";
                }else if(type === "103"){
                    return "8F40（锁车）车辆控制";
                }else if(type === "104"){
                    return "8F40（解锁）车辆控制";
                }else if(type === "105"){
                    return "8F40（解锁并取消激活）车辆控制";
                }else{
                    return "";
                }
            }else{
                if(type === "101"){
                    return "8F40 (activated) vehicle control";
                }else if(type === "102"){
                    return "8F40 (closed) vehicle control";
                }else if(type === "103"){
                    return "8F40 (Car Lock) Vehicle Control";
                }else if(type === "104"){
                    return "8F40 (Unlock) Vehicle Control";
                }else if(type === "105"){
                    return "8F40 (Unlock and Deactivate) Vehicle Control";
                }else{
                    return "";
                }
            }
        }

        /**
         * 获取解锁车业务类型 选项
         * @returns {string}
         */
        getBusinessTypeStr(): string
        {
            let result : string = "";
            let lang = ns.lang;

            this.BusinessTypes.forEach((item: any)=>{
                result += "<option value='" + item.id + "'>" + item[lang] + "</option>";
            });
            return result;
        }

        /**
         * 获取解锁车业务类型 描述
         * @param {string} type
         * @returns {string}
         */
        getBusinessTypeDes( type: string) : string
        {
            if( type == null )
            {
                return "";
            }
            return (this.getBusinessTypes(type) == null)? "": this.getBusinessTypes(type);
        }

        /**
         * 获取省份列表
         * @return {any}
         */
        getProvinceList(): any {
            var par: object = {};
            var result: any = "";
            ns.ajaxSync("queryProvinceList", par, function (data) {
                if (data.result != 0) {
                    return;
                }
                result = data.detail.dataList;
            });
            return result;
        }

        /**
         * 获取城市列表
         * @param provinceId {string}
         * @return {any}
         */
        getCityList(provinceId: number): any {
            var par: object = { "provinceId": provinceId };
            var result: any = "";
            ns.ajaxSync("queryCityList", par, function (data) {
                if (data.result != 0) {
                    return;
                }
                result = data.detail.dataList;
            });
            return result;
        }

        /**
         * 获取区县列表
         * @param cityId {string}
         * @return {string}
         */
        getCountryList(cityId: string): any {
            var par: object = { "cityId": cityId };
            var result = "";
            ns.ajaxSync("queryCountyList", par, function (data) {
                if (data.result != 0) {
                    return;
                }
                result = data.detail.dataList;
            });
            return result;
        }

        /**
         * 获取在线状态对照表
         * @param onlineStatus {string}
         * @return {string}
         */
        getOnlineStatusDes(onlineStatus: string): string {
            var des: string = "";
            switch (parseInt(onlineStatus)) {
                case -1:
                    des = "未知";
                    break;
                case 0:
                    des = "不在线";
                    break;
                case 1:
                    des = "在线静止";
                    break;
                case 2:
                    des = "在线且运行";
                    break;
            }

            return des;
        }

        /**
         * 获取车辆地图图标
         * @param car_status {number}
         */
        getCarIconInMap(car_status:number) {
            var iconStr = this.getCarTreeImg(car_status + "");
            if( ns.uType != null && ns.uType == "2" )
            {
                iconStr = iconStr.replace("carStatNoCharge","allCarStatus");
            }
            var size = new BMap.Size(28, 28);
            var icon = new BMap.Icon(iconStr,size);
            icon.setImageSize(size);
            return icon;
        }

        /**
         * 获取车辆树状结构图标
         * @param car_status {string}
         * @return {string}
         */
        getCarTreeImg(car_status: string): string {
            var icon = "";
            var icons = null;
            if( ns.uType != null && ns.uType == "2" )
            {
                icons = [
                    "../../images/carState/carStatNoCharge_1112.png",
                    "../../images/carState/carStatNoCharge_1212.png",
                    "../../images/carState/carStatNoCharge_1222.png",
                    "../../images/carState/carStatNoCharge_1111.png",
                    "../../images/carState/carStatNoCharge_1211.png",
                    "../../images/carState/carStatNoCharge_1221.png",
                    "../../images/carState/carStatNoCharge_2111.png",
                    "../../images/carState/carStatNoCharge_2211.png",
                    "../../images/carState/carStatNoCharge_2122.png",
                    "../../images/carState/carStatNoCharge_2122.png"
                ];
            }
            else
            {
                icons = [
                    "../../images/default/VehicleStatusOnline1.png",
                    "../../images/default/VehicleStatusOnlineStop1.png",
                    "../../images/default/VehicleStatusOnlineInvalid1.png",
                    "../../images/default/VehicleStatusOnlineAlarm.png",
                    "../../images/default/VehicleStatusOnlineStopAlarm.png",
                    "../../images/default/VehicleStatusOnlineInvalidAlarm.png",
                    "../../images/default/VehicleStatusOffline1.png",
                    "../../images/default/VehicleStatusOfflineStop1.png",
                    "../../images/default/VehicleStatusOfflineInvalid1.png"
                ];
            }
            switch (parseInt(car_status)) {
                // （在线行驶1，在线停车2，在线不定位3，在线行驶报警4，在线停车报警5，不在线行驶6,不在线停车7，不在线不定位8，默认值9）
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    icon = icons[parseInt(car_status)-1];
                    break;
                default:
                    icon = icons[8];
                    break;
            }
            return icon;
        }

        /**
         * 获取品牌列表
         * @return {string}
         */
        getBrandList(): string {
            var par = {};
            var result = "";
            ns.ajaxSync("queryVehBrandList", par, function (data) {

                if (data.result != 0) {
                    return;
                }
                var dataObj = data.detail.dataList;

                for (var i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i].brandId + "'>" + dataObj[i].brandName + "</option>";
                }
            });
            return result;
        }

        /**
         * 获取车型列表
         * @param brandId {any}
         * @return {string}
         */
        getProductList(brandId: any): string {
            var par = { "brandId": "N221" };
            var result = "";
            ns.ajaxSync("queryVehicleProductList", par, function (data) {

                if (data.result != 0) {
                    return;
                }
                let dataObj = data.detail.dataList;
                let attributeName = (ns.lang === 'zh')?"productName":"enNote";
                for (let i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i].productId + "'>" + dataObj[i][attributeName] + "</option>";
                }

            });
            return result;
        }

        /**
         * 获取行业类型列表
         * @return {string}
         */
        getIndustryType(): string {
            let str = '<option value=""></option>';
            this.industryType.forEach((item: any) => {
                str += "<option value='" + item.id + "'>" + item.type + "</option>";
            });

            return str;
        }

        /**
         * 获取环保局车辆类型
         * @return {string}
         */
        getEpaVehicleType(): string {
            let str = '';
            this.epaVehicleType.forEach((item: any) => {
                str += "<option value='" + item.id + "'>" + item.type + "</option>";
            });

            return str;
        }

        /**
         * 获取运输局车辆类型
         * @return {string}
         */
        getTransVehicleType(): string {
            let str = '';
            this.transVehicleType.forEach((item: any) => {
                str += "<option value='" + item.id + "'>" + item.type + "</option>";
            });

            return str;
        }

        /**
         * 获取排放水平
         * @return {string}
         */
        getEmissionLevelType(): string {
            let str = '';
            this.emissionLevelType.forEach((item: any) => {
                str += "<option value='" + item.id + "'>" + item.type + "</option>";
            });

            return str;
        }

        /**
         * 获取车款列表
         * @param productId {any}
         * @return {string}
         */
        getStyleList(productId: any): string {
            var par = { "productId": productId };
            var result = "";
            ns.ajaxSync("queryVehStyleList", par, function (data) {

                if (data.result != 0) {
                    return;
                }
                var dataObj = data.detail.dataList;

                for (var i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i].styleId + "' transmissionType='" + dataObj[i].transmissionType + "' displacement='" + dataObj[i].displacement + "'>" + dataObj[i].styleName + "</option>";
                }
            });
            return result;
        }

        /**
         * 获取生产商列表
         * @return {string}
         */
        getManufactorList(): string {
            var par = {};
            var result = "";
            ns.ajaxSync("queryManufactorList", par, function (data) {
                if (data.result != 0) {
                    return;
                }
                var dataObj = data.detail.dataList;

                for (var i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i].manufactorId + "'>" + dataObj[i].manufactorName + "</option>";
                }
            });
            return result;
        }

        /**
         * 获取设备型号列表
         * @param manufactorId {any}
         * @return {string}
         */
        getDeviceTypeList(manufactorId: any): string {
            var par = { "manufactorId": manufactorId };
            var result = "";
            ns.ajaxSync("queryDeviceTypeList", par, function (data) {
                if (data.result != 0) {
                    return;
                }
                var dataObj = data.detail.dataList;

                for (var i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i].typeId + "'>" + dataObj[i].typeName + "</option>";
                }
            });
            return result;
        }

        /**
         * 获取车辆类型列表
         * @return {string}
         */
        getVehicleTypeList(): string {
            var par = {};
            var result = "";
            ns.ajaxSync("queryVehicleTypeList", par, function (data) {
                if (data.result != 0) {
                    return;
                }
                var dataObj = data.detail.dataList;

                for (var i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i].typeId + "'>" + dataObj[i].typeName + "</option>";
                }
            });
            return result;
        }

        /**
         * 获取车辆运输行业列表
         * @return {string}
         */
        getIndustryList(): string {
            var par = {};
            var result = "";
            ns.ajaxSync("queryVehicleTypeList", par, function (data) {
                if (data.result != 0) {
                    return;
                }
                var dataObj = data.detail.dataList;

                for (var i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i].industryId + "'>" + dataObj[i].industry + "</option>";
                }
            });
            return result;
        }

        /**
         * 获取报警类型列表
         * @return {string}
         */
        getAlarmTypeList(): string {
            // var alarmType: { [index: string]: string } = {
            //     "-2": "离开围栏报警",
            //     "-1": "进入围栏报警",
            //     "0": "紧急报警",
            //     "1": "超速报警",
            //     "2": "疲劳驾驶",
            //     /*"3":"预警",
            //     "4":"GNSS模块发生故障",*/
            //     "5": "GNSS天线未接或被剪断",
            //     /*"6":"GNSS天线短路",*/
            //     "7": "终端主电源欠压",
            //     "8": "终端主电源掉电",
            //     /*"9":"终端LCD或显示器故障",
            //     "10":"TTS模块故障 ",
            //     "11":"摄像头故障 ",
            //     "12":"道路运输证IC卡模块故障 ",
            //     "13":"超速预警",
            //     "14":"疲劳驾驶预警",
            //     "18":"当天累计驾驶超时",
            //     "19":"超时停车",
            //     "20":"进出区域",*/
            //     /*"21":"进出路线",
            //     "22":"路段行驶时间不足/过长",*/
            //     "23": "路线偏离报警",
            //     /*"24":"车辆VSS故障",
            //     "25":"车辆油量异常",
            //     "26":"车辆被盗(通过车辆防盗器)",
            //     "27":"车辆非法点火",
            //     "28":"车辆非法位移",
            //     "29":"碰撞预警",
            //     "30":"侧翻预警",
            //     "31":"非法开门报警"*/
            // };

            let alarmType: any = [
            {"id": "", "zh": "所有", "en": "All"},
            {"id": "-2", "zh": "离开围栏报警", "en": "Leave the fence alarm"},
            {"id": "-1", "zh": "进入围栏报警", "en": "Enter Fence Alarm"},
            {"id": "0", "zh": "紧急报警", "en": "Emergency alarm"},
            {"id": "1", "zh": "超速报警", "en": "Speed alarm"},
            {"id": "2", "zh": "疲劳驾驶", "en": "Fatigue driving"},
            {"id": "5", "zh": "GNSS天线未接或被剪断", "en": "GNSS antenna is missed or cut"},
            {"id": "7", "zh": "终端主电源欠压", "en": "Terminal main power undervoltage"},
            {"id": "8", "zh": "终端主电源掉电", "en": "Terminal main power off"},
            {"id": "23", "zh": "路线偏离报警", "en": "Route departure alarm"}];

            let str :string = "";
            let lang = ns.lang;

            alarmType.forEach((item: any)=>{
                str += "<option value='" + item.id + "'>" + item[lang] + "</option>";
            });

            return str;
        }

        /**
         * 获取报警类型名称
         * @param key {number}
         * @return {string}
         */
        getAlarmTypeDes(key: number): string {
            var alarmType = [
                "",
                "开机事件",
                "关机事件",
                "急加速事件",
                "急减速事件",
                "急转弯事件",
                "危险变道事件",
                "点火事件",
                "熄火事件",
                "超速报警",
                "疲劳驾驶预警",
                "颠簸提醒 ",
                "专注度提醒 ",
                "车辆倾覆提醒 ",
                "碰撞提醒",
                "超载报警",
                "OBD参数预警",
                "电子栅栏报警",
                "车辆拖车报警",
                "人和宠物跌倒报警",
                "低电压报警"
            ];

            return alarmType[key];
        }

        /**
         * 获取发动机类型列表
         * @return {string}
         */
        getEngineTypeList(): string {
            var result = "";
            var enList = ["Stannic bavin", "Weichai", "HANGFA", "YUCAI", "Neville star", "Mann", "YUNNEI", "Cummins", "TAICAI"];
            ns.ajaxSync("queryEngineTypeList", {}, function (data) {
                if (data.result != 0) {
                    return;
                }
                let dataObj = data.detail.dataList;
                let nameObj = (ns.lang === 'zh')?dataObj: enList;
                for (let i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i] + "'>" + nameObj[i] + "</option>";
                }
            });
            return result;
        }

        /***
         * 获取 排放 选择类型列表
         *
         * @param {string} queryStr
         * @returns {string}
         */
        getPfSelectList( queryStr: string ): string
        {
            var result = "";
            ns.ajaxSync("querySystemConfigList", {"key":queryStr}, function (data) {
                if (data.result != 0) {
                    return;
                }
                var dataObj = data.detail.dataList;

                for (let i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i].key + "'>" + dataObj[i].value + "</option>";
                }
            });
            return result;
        }

        /**
         * 获取指令类型
         * @return {string}
         */
        getCmdTypeList(): string {
            var result = "";
            ns.ajaxSync("queryCMDList", {}, function (data) {
                if (data.result != 0) {
                    return;
                }
                let dataObj = data.detail.dataList;
                let attributeName = (ns.lang === 'zh')?"cmdNote":"enNote";
                for (let i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i].cmd + "'>" + dataObj[i][attributeName] + "</option>";
                }
            });
            return result;
        }

        /**
         * 获取操作类型
         * @return {string}
         */
        getOperLogTypeList(): string {
            var result = "";
            ns.ajaxSync("queryOperLogType", {}, function (data) {
                if (data.result != 0) {
                    return;
                }
                let dataObj = typeof data.detail.dataList == "undefined" ? [] : data.detail.dataList;

                let attributeName = (ns.lang === 'zh')?"operLogTypeName":"enNote";
                let neFlag = ns.uType === "2";
                for (let i = 0; i < dataObj.length; i++) {
                    //新能源不含有终端与客户管理
                    if( neFlag )
                    {
                        if( "终端管理" === dataObj[i].operLogTypeName ||
                            "客户管理" === dataObj[i].operLogTypeName )
                        {
                            continue;
                        }
                    }
                    result += "<option value='" + dataObj[i].operLogTypeName + "'>" + dataObj[i][attributeName] + "</option>";
                }

            });
            return result;
        }

        /**
         * 获取用户类型
         * @return {string}
         */
        getUserTypeList(): string {
            var result = "";
            ns.ajaxSync("queryUserTypeList", {}, function (data) {
                if (data.result != 0) {
                    return;
                }
                let dataObj = data.detail.dataList;
                let attributeName = (ns.lang === 'zh')?"userTypeName":"enNote";
                for (let i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i].userTypeId + "'>" + dataObj[i][attributeName] + "</option>";
                }

            });
            return result;
        }

        /**
         * 获取终端协议列表
         * @return {string}
         */
        getTerminalProtocolList(): string {
            var result = "";
            ns.ajaxSync("queryTerminalProtocolList", {}, function (data) {
                if (data.result != 0) {
                    return;
                }
                let dataObj = data.detail.dataList;

                for (let i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i] + "'>" + dataObj[i] + "</option>";
                }
            });
            return result;
        }

        /**
         * 获取终端型号列表
         * @return {string}
         */
        getTerminalTypeList(): string {
            let par = { "pageNo": 1, "onePageNum": -1 };
            var result = "";
            ns.ajaxSync("queryTerminalTypeList", par, function (data) {
                if (data.result != 0) {
                    return;
                }
                let dataObj = data.detail.dataList;

                for (let i = 0; i < dataObj.length; i++) {
                    result += "<option value='" + dataObj[i].typeId + "'>" + dataObj[i].typeName + "</option>";
                }
            });
            return result;
        }

        /**
         * 获取车辆状态简称
         * @param vehStatus {number}
         * @return {string}
         */
        getVehStatusSimpleDes(vehStatus: number): string {
            let des = "";

            switch (vehStatus) {
                case 1:
                    des = "在线";
                    break;
                case 2:
                    des = "在线";
                    break;
                case 3:
                    des = "在线";
                    break;
                case 4:
                    des = "在线";
                    break;
                case 5:
                    des = "在线";
                    break;
                case 6:
                    des = "在线";
                    break;
                case 7:
                    des = "离线";
                    break;
                case 8:
                    des = "离线";
                    break;
                case 9:
                    des = "离线";
                    break;
            }

            return des;
        }

        /**
         * 获取方向描述
         * @param direcStr
         * @return {string}
         */
        getDirectionDes(direcStr: string): string {
            var direcNum = parseFloat(direcStr);

            var des = "";
            if (direcNum == 0) {
                des = "正北"
            } else if (direcNum > 0 && direcNum < 90) {
                des = "东北";
            } else if (direcNum == 90) {
                des = "正东"
            } else if (direcNum > 90 && direcNum < 180) {
                des = "东南"
            } else if (direcNum == 180) {
                des = "正南"
            } else if (direcNum > 180 && direcNum < 270) {
                des = "西南"
            } else if (direcNum == 270) {
                des = "正西"
            } else if (direcNum > 270 && direcNum < 360) {
                des = "西北"
            }

            return des;
        }

        /**
         * 获取防控状态描述
         * @param index {number}
         * @return {string}
         */
        getPreventionDes(index: number): string {
            var list = [
                "未激活",
                "已激活",
                "已限速",
                "正在执行激活指令",
                "待执行激活指令",
                "正在执行锁车指令",
                "待执行锁车指令",
                "正在执行解锁指令",
                "待执行解锁指令",
                "正在执行激活关闭指令",
                "待执行激活关闭指令"
            ];

            return list[index];
        }

        /**
         * 获取大运VCU状态：
         * 根据扩展F0消息而来：
         * 0位：0、锁车关闭，1、锁车激活
         * 1位：0、已锁车，1、未锁车
         *
         * @param index {number}
         * @return {string}
         */
        getDyVcuPrevention(index: number): string {
            let list = [
                "未激活",
                "已锁车",
                "未激活",
                "未锁车",
                "无状态信息"
            ];
            return list[index];
        }

        /**
         * 获取大运防BCM状态
         * @param index {number}
         * @return {string}
         */
        getDyBcmPrevention(index: number): string {
            let list = [
                "关闭状态，门关窗关",
                "激活状态，门关窗关",
                "关闭状态，门开窗关",
                "激活状态，门开窗关",
                "关闭状态，门关窗开",
                "激活状态，门关窗开",
                "关闭状态，门开窗开",
                "激活状态，门开窗开",
                "无状态信息"
            ];
            return list[index];
        }

        /**
         * 获取can数据应显示字符
         * @param obj can对象
         * @param key  key
         * @param unit  单位
         */
        getViewContent(obj: any,key: string,unit: string): string
        {
            //can 对象为空表示数据未上传
            if(typeof obj === "undefined" || typeof obj[key] === "undefined"){
                return "NUll";
            }
            let value = obj[key];
            let flag = obj[key+"Flag"];

            //数据有效
            if(value !== "" && typeof flag === "undefined")
            {
                value = ns.formatNumberOne(value) + unit;
            }
            //空值
            else if(value === "" && typeof flag === "undefined")
            {
                value = "NUll";
            }

            //普通无效
            if(flag === "1")
            {
                value = "-"
            }
            //递增无效有值
            else if (value !== "" && flag === "2")
            {
                value = '<span style="color: purple">'+ns.formatNumberOne(value)+'</span>' + unit
            }
            //递增无效无值
            else if (value === "" && flag === "2")
            {
                value = '<span style="color: purple">NUll</span>'
            }


            return value;
        }

    }

    export class FUNC {

        constructor() {

        }

        modMyPass(): boolean {
            var oldPass = $("#myOldPass").val();
            var newPass = $("#myNewPass").val();
            var confirmPass = $("#myConfirmPass").val();

            if (oldPass == "") {
                ns.genNoty("warning", "请填写旧密码");//找不到定义
                return false;
            }

            if (newPass == "") {
                ns.genNoty("warning", "请填写新密码");//找不到定义
                return false;
            }

            if (confirmPass == "") {
                ns.genNoty("warning", "请填写确认密码");//找不到定义
                return false;
            }

            if (confirmPass != newPass) {
                ns.genNoty("warning", "确认密码需和新密码一致");//找不到定义
                return false;
            }


            var par = {
                "userName": ns.userName,
                "oldPassword": hex_md5(oldPass),
                "password": hex_md5(newPass)
            };
            ns.ajax("updatePassword", par, function (data) {
                if (data.result != 0) {
                    ns.genNoty("error", data.resultNote);//找不到定义
                    ns.module.user.checkUserIsLogin(data.result);
                    return;
                }

                ns.genNoty("success", "修改成功");//找不到定义
                var opt: { [index: string]: boolean } = {};
                opt["closed"] = true;
                $('#modMyPassPanel').dialog(opt);
                B.searchCorp($('#pagination1').pagination('options').pageNumber);//方法searchCorp()方法在webapp源码中无定义
            });

            return true;
        }

        /**
         * 界面样式（高宽）自适应调整
         * @deprecated YWTS.FUNC.initDom() 替代
         */
        resizeDom(): void {
            if (ns.menuId && (ns.menuId.indexOf("menu_0") >= 0)) {
                var sysTopHeight = $('.head').height();
                var sysHtmlHeight = document.documentElement.clientHeight;
                $(".vehicleTabCon,.ServiceTabCon,.RiskPreventTabCon").css({
                    "height": sysHtmlHeight - sysTopHeight - 70,
                });
                $(".mainRight").css({
                    "height": sysHtmlHeight - sysTopHeight - 72 + 25,
                });
            } else {
                //初始化dom高度
                $(".mainContant").eq(0).height($(window).height() - 13);
                $(".statisRightCont").eq(0).height($(".mainContant").height() - 10)
                $(".statisLeftNav").eq(0).height($(".mainContant").height() - 10)

                var sysRightCh = $('.statisRightCont').height();
                var sysRightTabH = $('.sellStateQuery').outerHeight();
                $(".sellStateResult").css({
                    "height": sysRightCh - sysRightTabH - 72,
                    "overflow": "auto"
                });
            }
        }

        /**
         *  通用应答校验
         *  <li>应答成功，返回true</li>
         *  <li>应答失败，返回resultNote 或 false</li>
         *
         * @param {string} result
         * @returns {boolean | string}
         */
        public static commonResultCheck( result: string ): ResultObj
        {
            //初始化应答对象，默认应答失败
            let resultObj : ResultObj = {
                status : 1,
                resultJson : null
            };
            if( null !== result && 0 < result.length )
            {
                try {
                    let jsonData = JSON.parse(result);
                    if( null !== jsonData )
                    {
                        resultObj.resultJson = jsonData;
                        if( 0 === jsonData.result )
                        {
                            resultObj.status = 0;
                        }
                        else
                        {
                            //应答失败后,获取应答Note返回
                            if( "string" === typeof jsonData.resultNote && 0 < jsonData.resultNote.length )
                            {
                                resultObj.resultNote = jsonData.resultNote;
                            }
                        }
                    }
                } catch (e) {
                    console.error("result check jsonParse error" + result,e);
                }
            }

            return resultObj;
        }

        /***
         * 文件操作前检查：主要是文件名校验与提示
         * @param {string} file 操作选中文件
         * @param {Array<string>} fileSuffix 文件后缀限定集合（请使用小写格式），为空则不校验格式
         * @returns {boolean} 校验结果，通过为true
         */
        public static fileOprPreCheck(file: string, fileSuffix : Array<string>|null ): boolean
        {
            if(file === "")
            {
                let tipMsg = "请先选择文件";
                if( null !== fileSuffix && typeof fileSuffix !== "undefined" && fileSuffix.length > 0 )
                {
                    tipMsg = tipMsg + ",文件后缀格式:" + fileSuffix.join(",");
                }
                $.jBox.tip(tipMsg,"warning");
                return false;
            }
            else
            {
                if( null !== fileSuffix && typeof fileSuffix !== "undefined" && fileSuffix.length > 0 )
                {
                    var fileNameArray = file.split(".");
                    if($.inArray(fileNameArray[fileNameArray.length-1].toLocaleLowerCase(),fileSuffix) === -1){
                        $.jBox.tip("只支持有限格式：" + fileSuffix.join(",")+"的文件","warning");
                        return false;
                    }
                }
            }
            return true;
        }

        /**
         * 图片浏览事件函数 需要设为图标预览的change事件
         * 具体行为
         * <li>1.选中图片后，文件名赋值到指定text文本</li>
         * <li>2.本地图片预览</li>
         * @param textId    文件全路径待回填文本的元素ID,为null是表示无需回填
         * @param fileViewId  文件预览展示的元素的ID
         * @param e 选中文件事件
         * @param fileSuffix 文件后缀限制数组
         */
        public static browseFile(textId:string|null, fileViewId:string|null, e:any, fileSuffix : Array<string>|null )
        {
            //1.选中图片后，文件名赋值到指定text文本
            if( null != textId && "undefined" !== typeof textId )
            {
                $("#"+textId).val(($(e.target).val() !== null)?$(e.target).val():"");
            }

            //2.本地图片预览
            if( null !== fileViewId )
            {
                if( null === fileSuffix || "undefined" === typeof fileSuffix )
                {
                    fileSuffix = null;
                }
                YWTS.FUNC.previewFile(e,fileViewId,fileSuffix);
            }
        }

        /**
         * 本地预览上传文件
         * <h1>暂只支持图片本地预览，文本预览待支持</h1>
         * @param e  文件选择change 事件对象
         * @param fileViewId  文件预览展示的元素ID
         * @param {Array<string>} fileSuffix 文件后缀限制数组
         * @returns {boolean}
         */
        public static previewFile(e:any, fileViewId:string, fileSuffix : Array<string>|null ): void
        {
            //拿到原始对象
            let file = e.target.files[0];
            if( "undefined" === typeof file )
            {
                //设置图片为默认图片
                $("#"+fileViewId).attr('src',"../../images/default/serverDefuImg.png");
            }
            //类型为文件时
            else if( file.type.indexOf('image') === 0 )
            {
                if( YWTS.FUNC.fileOprPreCheck(file.name,fileSuffix) )
                {
                    //当前对象的地址
                    let thisSrc  = URL.createObjectURL(file);
                    //创建img对象
                    let img = $("#" + fileViewId).attr('src',thisSrc);
                    //文件加载成功以后，渲染到页面
                    img.load(function(){
                        //资源 内存释放
                        URL.revokeObjectURL(thisSrc);
                    });
                }
            }
        }

        /**
         * 页面初始化（通用）
         * 初始化菜单选择样式
         * 初始化dom高度
         */
        public static initDom():void
        {
            // 一级菜单外部选中，frame内部不做处理选中
            // $("#" + ns.menuId).addClass("currentPageLnav");

            //dom高度初始化
            //整个页面框
            let $mainContant = $("div.mainContant").eq(0);

            //页面左侧菜单框
            let $statisLeftNav = $("div.statisLeftNav").eq(0);
            $mainContant.height($(window).height() - 13);
            let mcHeight = $mainContant.height();
            $statisLeftNav.height(mcHeight-10);

            $("#menuCon").css({
                "max-height": $statisLeftNav.height(),
                "height": $statisLeftNav.height(),
                "overflow": "auto"
            });

            if( typeof  ns.subMenuId !== "undefined" && ns.subMenuId !== null)
            {
                // 二级菜单选中
                $("#" + ns.subMenuId).addClass("currentLnav");
                //查询框
                let $sysRightTabH = $('div.sellStateQuery').eq(0);
                //页面右侧框
                let $statisRightCont = $("div.statisRightCont").eq(0);
                $statisRightCont.height(mcHeight - 10);
                $("div.sellStateResult").css({
                    "height": $statisRightCont.height() - $sysRightTabH.outerHeight() - 72,
                    "overflow": "auto"
                });
            }
        }

        /**
         * 重置指定元素区域内的查询条件
         * @param {string} selector 元素ID
         */
        public static resetFilter(selector: string):void
        {
            let selectDom = $('#'+ selector);
            selectDom.find("input[type='text']").val("");
            selectDom.find("input[type='hidden']").val("");
            selectDom.find("select").val("");
            $("input:checkbox:checked").removeAttr("checked");
        }

        /**
         * 非空检查，并获取值
         * @param {string} domId
         * @param {string} blankWarning
         * @param resultSetObj
         * @returns {boolean}
         */
        public static blankCheckAndGet(domId:string, blankWarning:string, resultSetObj: any)
        {
            let dom = $("#"+domId);
            let value = $.trim(dom.val());
            if (value == "") {
                $.jBox.tip(blankWarning, 'warning');
                dom.focus();
                return false;
            }
            resultSetObj[domId] = value;
            return true;
        }

        /**
         * 可选项非空检查，并获取值
         * <p>
         *     针对情况：多个值至少一个必填的情况
         * </p>
         * @param {string} domId
         * @param {string} blankWarning
         * @param resultSetObj
         * @returns {boolean}
         */
        public static optionalBlankCheckAndGet(domIdList:Array<string>, blankWarning:string, resultSetObj: any)
        {
            //非空标志
            var notBlank = false;
            //为空的元素
            var blankDom = null;
            for( let domId of domIdList)
            {
                let dom = $("#"+domId);
                let value = $.trim(dom.val());
                resultSetObj[domId] = value;
                if( !notBlank && value == "" )
                {
                    blankDom = blankDom == null?dom:blankDom;
                }
                else
                {
                    notBlank = true;
                }
            }

            if (!notBlank) {
                $.jBox.tip(blankWarning, 'warning');
                if( blankDom != null )
                {
                    blankDom.focus();
                }
                return false;
            }
            return true;
        }

        /**
         * 元素值非空检查，并获取值
         * @param {string} valueId
         * @param {string} nameId
         * @param {string} blankWarning
         * @param resultSetObj
         * @returns {boolean}
         */
        public static chooseBlankCheckAndGet(valueId:string, nameId:string, blankWarning:string, resultSetObj: any)
        {
            let value = $.trim($("#"+valueId).val());
            if (value == "") {
                $.jBox.tip(blankWarning, 'warning');
                $("#"+nameId).focus();
                return false;
            }
            resultSetObj[valueId] = value;
            return true;
        }
    }
}

//这儿定义一个全局的 ns 变量, 这样子原来的  JS 代码使用到 这个的都不用动
let ns = new YWTS.NS();

